import gql from 'graphql-tag';

export const UPDATE_ARTIST_FOLLOWING = gql`
  mutation updateArtistFollowing(
    $artistId: Int!
    $userId: Int!
    $following: Boolean!
  ) {
    updateArtistFollowing(
      artistId: $artistId
      userId: $userId
      following: $following
    ) {
      artistFollowing {
        id
        following
      }
    }
  }
`;
