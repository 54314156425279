import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

interface Props {
  formatMessage: IntlShape['formatMessage'];
}

export const SignUpFormSchema = ({ formatMessage }: Props) =>
  Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'form.validations.email.invalid' }))
      .required(formatMessage({ id: 'form.validations.email.required' })),
    password: Yup.string()
      .min(8, `${formatMessage({ id: 'form.validations.password.length' })}`)
      .required(
        `${formatMessage({ id: 'form.validations.password.required' })}`
      ),
    city: Yup.object().nullable(),
    mobile: Yup.string()
      .matches(/^[-\d().+]+( [-\d.]+)*$/, {
        message: formatMessage({
          id: 'form.validations.mobile.invalid',
        }),
      })
      .nullable(),
  });
