import { useEffect } from 'react';

import { User } from 'app/typings';
import { CheckoutEvent } from 'app/fan/utils/checkout';
import { GetEventAttendeeForFanLazy } from 'app/fan/graphql/eventAttendees/queryHooks';

export const useEventAttendee = (
  user: User | null,
  event: CheckoutEvent | null
) => {
  const [
    loadEventAttendee,
    { called, loading, data, refetch },
  ] = GetEventAttendeeForFanLazy();

  useEffect(() => {
    if (user && event && !called) {
      loadEventAttendee({
        variables: { userId: String(user.id), eventId: event.id },
      });
    }
  }, [user, event, loadEventAttendee]);

  return {
    eventAttendeeCalled: called,
    eventAttendeeLoading: loading,
    eventAttendee: data ? data.eventAttendee : null,
    refetch,
  };
};
