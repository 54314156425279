import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { AuthFormType, PaymentMethod, User } from 'app/typings';
import { FEATURED_SET_EVENT } from 'app/shared/utils/events';
import { default as useModal, UseModalValue } from 'app/shared/utils/useModal';
import { AuthContext } from 'app/shared/context/Auth';
import {
  CheckoutEvent,
  CheckoutEventAttendee,
  CheckoutStage,
  CheckoutStatus,
  DEFAULT_TICKETS,
  PrimaryCTAData,
  PromoCodeValue,
  useCheckoutEvent,
  useEventAttendee,
  useGuestCheckoutEnabled,
  usePromoCode,
  useSavedCards,
  useVisaPartnershipCheckoutLogic,
  VisaPartnershipCheckoutLogic,
} from 'app/fan/utils/checkout';
import { GetEventAttendeeForExpressGuestCheckout } from 'app/fan/graphql/eventAttendees/queryHooks';

interface CheckoutContextProviderProps {
  eventId?: string | number;
}

export interface CheckoutContextValue {
  event: CheckoutEvent | null;
  eventId?: string | number;
  outsideError?: string;
  setOutsideError: (outsideError?: string) => void;
  activeCardError?: string;
  setActiveCardError: (activeCardError?: string) => void;
  numberOfTickets: number;
  setNumberOfTickets: (numberOfTickets: number) => void;
  isFeaturedSetEvent: boolean;
  eventUrl?: string;
  setEventUrl: (eventUrl: string) => void;
  eventAttendee: CheckoutEventAttendee | null;
  stage: CheckoutStage;
  setStage: React.Dispatch<React.SetStateAction<CheckoutStage>>;
  primaryCTAData: PrimaryCTAData | null;
  setPrimaryCTAData: (primaryCTAData: PrimaryCTAData) => void;
  isLoading: boolean;
  editOrderModal: UseModalValue;
  toggleEditOrderModal: () => void;
  visaPartnershipCheckoutLogic: VisaPartnershipCheckoutLogic;
  checkoutStatus: number;
  setCheckoutStatus: (status: number) => void;
  paymentFormErrors: any;
  setPaymentFormErrors: (errors: any) => void;
  savedCards: PaymentMethod[];
  eventRefetch: () => void;
  loggedIn: boolean;
  eventAttendeeRefetch: () => void;
  externalConfirmationId?: string;
  setExternalConfirmationId: (externalConfirmationId?: string) => void;
  guestCheckoutEnabled?: boolean;
  guestUser: User | null;
  setGuestUser: (user: User) => void;
  authFormType: AuthFormType;
  setAuthFormType: React.Dispatch<React.SetStateAction<AuthFormType>>;
  isAuthModalOpen: boolean;
  setIsAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  optinGuestMarketing: boolean;
  setOptinGuestMarketing: (optinGuestMarketing: boolean) => void;
  promoCodeValue: PromoCodeValue;
  wasRedirectedFromGuestExpressCheckout: boolean;
}

export const CheckoutContext = createContext<CheckoutContextValue | null>(null);

function getStartingNumberOfTickets(searchParams: URLSearchParams) {
  const ticketQuantity = searchParams?.get('ticket_quantity');

  if (ticketQuantity) {
    return Number(ticketQuantity);
  } else {
    return DEFAULT_TICKETS;
  }
}

export function useCheckoutContextValue(): CheckoutContextValue {
  const value = useContext(CheckoutContext);

  if (value === null) {
    throw new Error(
      'useCheckoutContextValue must be called within a child or grandchild of a CheckoutContext.Provider!'
    );
  }
  return value;
}

export const CheckoutContextProvider: React.FC<CheckoutContextProviderProps> = ({
  children,
  eventId,
}) => {
  const [stage, setStage] = useState<CheckoutStage>('SHOPPING_CART');
  const [authFormType, setAuthFormType] = useState<AuthFormType>('signUp');
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [externalConfirmationId, setExternalConfirmationId] = useState<
    string
  >();
  const [outsideError, setOutsideError] = useState<string>();
  const [activeCardError, setActiveCardError] = useState<string | undefined>(
    undefined
  );
  const [optinGuestMarketing, setOptinGuestMarketing] = useState(true);
  const [eventUrl, setEventUrl] = useState<string>();

  const [guestUser, setGuestUser] = useState<User | null>(null);

  const [searchParams] = useSearchParams();
  const [numberOfTickets, setNumberOfTickets] = useState(
    getStartingNumberOfTickets(searchParams)
  );
  const promoCodeValue = usePromoCode(eventId);

  const { user: loggedInUser, loading: sessionLoading, loggedIn } = useContext(
    AuthContext
  );
  const {
    event,
    eventLoading,
    refetch: eventRefetch,
    eventCalled,
  } = useCheckoutEvent(eventId);
  const {
    eventAttendee,
    eventAttendeeLoading,
    refetch: eventAttendeeRefetch,
  } = useEventAttendee(loggedInUser, event);
  const [primaryCTAData, setPrimaryCTAData] = useState<PrimaryCTAData | null>(
    null
  );

  // Guest Express Checkout redirects to the Order Complete stage
  const guestExpressCheckoutEventAttendeeId = searchParams.get('eaid');
  const guestExpressCheckoutUserId = searchParams.get('uid');
  const wasRedirectedFromGuestExpressCheckout =
    !!guestExpressCheckoutEventAttendeeId && !!guestExpressCheckoutUserId;

  const [
    getGuestExpressCheckoutEventAttendee,
    { data: guestExpressCheckoutEventAttendeeData },
  ] = GetEventAttendeeForExpressGuestCheckout({
    id: Number(guestExpressCheckoutEventAttendeeId),
  });

  useEffect(() => {
    if (wasRedirectedFromGuestExpressCheckout) {
      getGuestExpressCheckoutEventAttendee();
    }
  }, [guestExpressCheckoutEventAttendeeId, guestExpressCheckoutUserId]);

  useEffect(() => {
    if (guestExpressCheckoutEventAttendeeData?.eventAttendee?.user) {
      const {
        eventAttendee: { user: guestUser },
      } = guestExpressCheckoutEventAttendeeData;
      if (guestUser.id.toString() === guestExpressCheckoutUserId) {
        setStage('ORDER_COMPLETE');
        setGuestUser(guestUser);
      }
    }
  }, [guestExpressCheckoutEventAttendeeData]);

  const [editOrderModal, toggleEditOrderModal] = useModal({
    animateClose: true,
    animationTimeout: 500,
  });

  const [checkoutStatus, setCheckoutStatus] = useState<number>(
    CheckoutStatus.NONE
  );
  const [paymentFormErrors, setPaymentFormErrors] = useState({});
  const { savedCards } = useSavedCards(loggedInUser);

  const isFeaturedSetEvent = event ? event.type === FEATURED_SET_EVENT : false;
  useEffect(() => {
    if (event !== null) {
      const url = isFeaturedSetEvent
        ? `/featuring/${event?.cachedSlug}`
        : event?.isSpecialEvent
        ? event?.specialEventUrl
        : `/events/${event?.id}`;
      setEventUrl(url);
    }
  }, [event, isFeaturedSetEvent]);

  const visaPartnershipCheckoutLogic = useVisaPartnershipCheckoutLogic({
    event,
    eventId,
    eventAttendee,
    isExpressCheckout: false,
    numberOfTickets,
    user: loggedInUser || undefined,
  });

  useEffect(() => {
    setActiveCardError(visaPartnershipCheckoutLogic.activeCardError);
  }, [visaPartnershipCheckoutLogic.activeCardError]);

  const guestCheckoutEnabled = useGuestCheckoutEnabled({
    event,
    isExpressCheckout: false,
  });

  const isLoading =
    !eventCalled ||
    eventLoading ||
    !!sessionLoading ||
    eventAttendeeLoading ||
    visaPartnershipCheckoutLogic.cardRedemptionsStatusLoading;

  // This useMemo call is an optimization to prevent unnecessary re-renders of components that consume the context
  const checkoutContextValue = useMemo(
    () => ({
      event,
      eventId,
      outsideError,
      setOutsideError,
      activeCardError,
      setActiveCardError,
      numberOfTickets,
      setNumberOfTickets,
      isFeaturedSetEvent,
      eventUrl,
      setEventUrl,
      eventAttendee,
      stage,
      setStage,
      primaryCTAData,
      setPrimaryCTAData,
      isLoading,
      editOrderModal,
      toggleEditOrderModal,
      visaPartnershipCheckoutLogic,
      checkoutStatus,
      setCheckoutStatus,
      paymentFormErrors,
      setPaymentFormErrors,
      savedCards,
      eventRefetch,
      loggedIn,
      eventAttendeeRefetch,
      externalConfirmationId,
      setExternalConfirmationId,
      guestCheckoutEnabled,
      guestUser,
      setGuestUser,
      authFormType,
      setAuthFormType,
      isAuthModalOpen,
      setIsAuthModalOpen,
      optinGuestMarketing,
      setOptinGuestMarketing,
      promoCodeValue,
      wasRedirectedFromGuestExpressCheckout,
    }),
    [
      event,
      eventId,
      outsideError,
      activeCardError,
      numberOfTickets,
      isFeaturedSetEvent,
      eventUrl,
      eventAttendee,
      stage,
      primaryCTAData,
      isLoading,
      editOrderModal,
      toggleEditOrderModal,
      visaPartnershipCheckoutLogic,
      checkoutStatus,
      paymentFormErrors,
      savedCards,
      eventRefetch,
      loggedIn,
      eventAttendeeRefetch,
      externalConfirmationId,
      guestCheckoutEnabled,
      guestUser,
      authFormType,
      isAuthModalOpen,
      optinGuestMarketing,
      promoCodeValue,
      wasRedirectedFromGuestExpressCheckout,
    ]
  );

  return (
    <CheckoutContext.Provider value={checkoutContextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};
