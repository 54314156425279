import { useContext, useEffect } from 'react';

import { CityContext } from 'app/shared/context/City';
import { CheckoutEvent } from 'app/fan/utils/checkout';
import { GetCheckoutEventLazy } from 'app/fan/graphql/events/queryHooks';

export interface GetCheckoutEventResponse {
  event: CheckoutEvent;
}

export function useCheckoutEvent(eventId?: string | number) {
  const [
    fetchEvent,
    { called, loading, data, refetch },
  ] = GetCheckoutEventLazy({ id: Number(eventId) });

  const { setLastViewedCity } = useContext(CityContext);

  useEffect(() => {
    if (eventId) {
      fetchEvent();
    }
  }, [eventId, fetchEvent]);

  const event = data?.event || null;

  useEffect(() => {
    if (event?.city) {
      setLastViewedCity(event.city);
    }
  }, [event]);

  return {
    eventCalled: called,
    eventLoading: loading,
    event,
    refetch,
  };
}
