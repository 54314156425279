import React from 'react';

import { groupBy as groupArray } from 'app/shared/utils/array';
import { Box, DropdownSectionHeader } from 'app/fan/components/atoms';

import { SimpleOptions, SimpleOptionsProps } from './Simple';

interface Props extends SimpleOptionsProps {
  'data-qaid'?: string;
  groupBy: (option: any) => string;
}

export const GroupedOptions = ({
  options,
  groupBy,
  'data-qaid': qaId,
  invertColors,
  ...rest
}: Props) => {
  const groups = groupArray(options, groupBy);

  const keys = Object.keys(groups);

  return (
    <Box overflowY="scroll" maxHeight="inherit">
      {keys.map(key => (
        <div key={`group-${key}`} data-qaid={`${qaId}-group`}>
          <DropdownSectionHeader
            data-qaid={`${qaId}-group-${key}-header`}
            invertColors={invertColors}
          >
            {key}
          </DropdownSectionHeader>

          <SimpleOptions
            options={groups[key]}
            invertColors={invertColors}
            {...rest}
          />
        </div>
      ))}
    </Box>
  );
};

export default GroupedOptions;
