export const DEFAULT_TICKETS = 2;

export const CheckoutStatus = {
  NONE: 0,
  READY: 1,
  LOADING: 2,
  ERROR: 3,
};

export const GENERIC_ERROR = 'Something went wrong... Please try again later!';

export const PAYMENT_METHOD_APPLE_PAY = 'Apple Pay';
export const PAYMENT_METHOD_GOOGLE_PAY = 'Google Pay';
export const PAYMENT_METHOD_CREDIT_CARD = 'Credit Card';
