import { dateFormatter, uses12HourClock } from 'app/shared/utils/datetime';

// dateTime = e.g. '2022-11-16T20:00:00Z'
// timezone = e.g. 'America/New_York'
// countryCode = e.g. 'US'
export const formattedEventDateAndTime = (
  dateTime: any,
  timezone?: string,
  countryCode?: string
) => {
  if (!dateTime) {
    return {};
  }

  const is12HourClock = countryCode ? uses12HourClock(countryCode) : true;

  const dateFormat = is12HourClock
    ? 'longWeekdayCommaLongMonthDate'
    : 'longWeekdayCommaDateLongMonth';
  const timeFormat = is12HourClock
    ? 'hoursAndMinutesWithSpacedAMPM'
    : 'hoursAndMinutesWithoutAMPM';
  const shortMonthFormat = 'shortMonth';
  const dayFormat = 'date';
  const shortWeekdayFormat = 'shortWeekday';

  const eventDate = dateFormatter(dateTime, dateFormat, timezone);
  const eventTime = dateFormatter(dateTime, timeFormat, timezone);
  const eventShortMonth = dateFormatter(dateTime, shortMonthFormat, timezone);
  const eventDay = dateFormatter(dateTime, dayFormat, timezone);
  const eventShortWeekday = dateFormatter(
    dateTime,
    shortWeekdayFormat,
    timezone
  );

  // Returns:
  // eventDate = e.g. 'Saturday, January 6'
  // eventTime = e.g. '7:30 PM'
  // eventShortMonth =  e.g. 'Jan'
  // eventDay = e.g. '6'
  // eventShortWeekday = e.g. 'Sat'
  return {
    eventDate,
    eventTime,
    eventShortMonth,
    eventDay,
    eventShortWeekday,
  };
};
