import { useState } from 'react';

import { ValidatePromoCodeData } from 'app/fan/utils/checkout';
import { GetPromoCodeValidationLazy } from 'app/fan/graphql/promoCodes/queryHooks';

interface Props {
  eventId?: string | number;
  onClearPromoCode?: () => void;
}

export const useValidatePromoCode = ({
  eventId,
  onClearPromoCode,
}: Props): ValidatePromoCodeData => {
  const [validated, setValidated] = useState(false);
  const [clearPromoCodeCallback, setClearPromoCodeCallback] = useState<
    () => void
  >(() => {});
  const [
    loadValidatePromoCode,
    validatePromoCodeResponse,
  ] = GetPromoCodeValidationLazy({});

  function validatePromoCode(code?: string) {
    if (eventId && !!code) {
      setValidated(true);

      return loadValidatePromoCode({
        variables: {
          code: code.trim(),
          eventId: parseInt(eventId.toString()),
        },
      });
    }
  }

  function resetValidated() {
    setValidated(false);
  }

  function clearPromoCode() {
    resetValidated();
    if (clearPromoCodeCallback) {
      clearPromoCodeCallback();
    }
    if (onClearPromoCode) {
      onClearPromoCode();
    }
  }

  return {
    validatePromoCode,
    validatePromoCodeResponse,
    validated,
    resetValidated,
    clearPromoCode,
    setClearPromoCodeCallback,
  };
};
