import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AuthContext } from 'app/shared/context/Auth';
import { CheckoutEvent } from 'app/fan/utils/checkout';
import { isUpcomingEvent } from 'app/fan/utils/eventHelper';

interface Params {
  event: CheckoutEvent | null;
  isExpressCheckout: boolean;
}

// Returns whether the event is eligible for Regular Guest Checkout
function isEventIsEligibleForGuestCheckout(
  event: CheckoutEvent | null,
  searchParams: URLSearchParams
) {
  const isApplyFlow = event?.attendeeFlow === 'apply';
  const isCompetition = event?.competition && event?.competition.id;
  const forceStartedEventState = searchParams.get('sofarStarted') == 'true';
  const eventIsUpcoming =
    !forceStartedEventState && event && isUpcomingEvent(event);

  return !!(event && !isApplyFlow && !isCompetition && eventIsUpcoming);
}

// Returns whether Regular Guest Checkout is enabled
export const useGuestCheckoutEnabled = ({
  event,
  isExpressCheckout,
}: Params) => {
  const { loggedIn, loading: sessionLoading } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const eventIsEligibleForGuestCheckout = isEventIsEligibleForGuestCheckout(
    event,
    searchParams
  );

  return (
    !sessionLoading &&
    !loggedIn &&
    eventIsEligibleForGuestCheckout &&
    !isExpressCheckout
  );
};
