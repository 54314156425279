import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  EventAttendeeForGetEventsQuery,
  Tag,
  Theme,
  User,
  VenueForFanFacingEvent,
} from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { AuthContext } from 'app/shared/context/Auth';

export interface EventForEventTags {
  id: string;
  isSoldOut: boolean;
  isPurchasable: boolean;
  isAppliable: boolean;
  attendeeFlow: 'buy' | 'apply';
  attendee?: EventAttendeeForGetEventsQuery;
  onPresale: boolean;
  theme?: Theme;
  isVenueConfirmed: boolean;
  venue: VenueForFanFacingEvent;
}

function filterByVisibleToFans(tag: Tag) {
  return !!tag.isVisibleToFans;
}

function getVenueCategoryTag(event: EventForEventTags) {
  const { venue } = event;
  const venueCategories = venue?.venueCategories;
  const visibleCategories: Tag[] | undefined = venueCategories?.filter(
    filterByVisibleToFans
  );

  if (!visibleCategories || !visibleCategories[0]) {
    return;
  }

  return visibleCategories[0];
}

export function shouldDisplaySoldOutEventTag(
  event: EventForEventTags,
  user: User | null,
  userAttendeeState?: string
) {
  const attendeeState = userAttendeeState || event.attendee?.currentState;

  const isInConfirmableState =
    attendeeState === 'guestlisted' || attendeeState === 'cant_go';
  const notYetApplied = !user || !event.attendee || attendeeState === '';

  return (
    event.isSoldOut ||
    (event.attendeeFlow === 'buy' && !event.isPurchasable) ||
    (event.attendeeFlow === 'apply' &&
      ((notYetApplied && !event.isAppliable) ||
        (isInConfirmableState && !event.isPurchasable)))
  );
}

export const useGetEventTagsProps = () => {
  const { formatMessage } = useIntl();
  const { colors } = useCurrentTheme();
  const { user } = useContext(AuthContext);

  const defaultProps = {
    b: colors.accent.primaryAccent.base,
    color: colors.primary.black.base,
  };

  const getEventTagsProps = useCallback(
    (event: EventForEventTags, userAttendeeState?: string): any[] => {
      if (shouldDisplaySoldOutEventTag(event, user, userAttendeeState)) {
        return [
          {
            tag: formatMessage({ id: 'shared.soldOut' }),
            backgroundColor: colors.messaging.error.dark,
            b: colors.messaging.error.dark,
            color: colors.primary.white.base,
          },
        ];
      }

      if (event.onPresale) {
        return [
          {
            tag: formatMessage({ id: 'shared.visaPresale' }),
            ...defaultProps,
          },
        ];
      }

      if (event.theme) {
        return [{ tag: event.theme.title, ...defaultProps }];
      }

      if (event.isVenueConfirmed && !!event.venue) {
        const venueCategoryTag = getVenueCategoryTag(event);

        if (venueCategoryTag) {
          return [{ tag: venueCategoryTag.name, ...defaultProps }];
        }
      }

      return [];
    },
    []
  );

  return getEventTagsProps;
};
