import React, { useState } from 'react';
import styled from 'styled-components';

import { withFontCSS, WithFontProps } from 'app/shared/components/styleUtils';
import { ActionButton } from 'app/fan/components/molecules/ActionButton';

export interface PillProps {
  selected: boolean;
  disabled: boolean;
  onClick: (filterKey: string) => void;
  filterKey: string;
  displayName: string;
}

const StyledActionButton = styled(ActionButton)<WithFontProps>`
  height: 36px !important;
  ${withFontCSS};
`;

export const FilterPill: React.FC<PillProps> = ({
  selected,
  disabled,
  onClick,
  filterKey,
  displayName,
}) => {
  const [isHoverState, setIsHoverState] = useState(false);
  const handleClick = (e: any) => {
    e.currentTarget.blur();
    onClick(filterKey);
  };

  return (
    <StyledActionButton
      variant={selected || isHoverState ? 'primary' : 'tertiary'}
      onClick={handleClick}
      disabled={disabled}
      onMouseEnter={() => setIsHoverState(true)}
      onMouseLeave={() => setIsHoverState(false)}
      fontSize="fontSize75"
      fontWeight="bold"
      textWrap="nowrap"
      textTransform="uppercase"
      data-qaid={`filter-pill-${filterKey}-selected-${selected}`}
    >
      {displayName}
    </StyledActionButton>
  );
};
