import gql from 'graphql-tag';

export const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $firstName: String
    $lastName: String
    $email: String!
    $mobile: MobileAttributes
    $optInFanMarketingSmses: Boolean
    $optOutFanMarketingEmails: Boolean
    $password: String!
    $citySlug: String
    $reCaptchaToken: String
  ) {
    createAccount(
      firstName: $firstName
      lastName: $lastName
      email: $email
      optOutFanMarketingEmails: $optOutFanMarketingEmails
      optInFanMarketingSmses: $optInFanMarketingSmses
      mobile: $mobile
      password: $password
      citySlug: $citySlug
      reCaptchaToken: $reCaptchaToken
    ) {
      sessionId
      isNewAccount
      user {
        id
        isGuest
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
        optInFanMarketingSmses
        homeCity {
          id
          country {
            id
            countryCode
          }
        }
      }
    }
  }
`;

export const PASSWORD_RESET_REQUEST = gql`
  mutation passwordResetRequest($email: String!) {
    passwordResetRequest(email: $email) {
      success
    }
  }
`;

// Used to updte password on password reset
export const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $resetToken: String!
    $newPassword: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      resetToken: $resetToken
      newPassword: $newPassword
      passwordConfirmation: $passwordConfirmation
    ) {
      success
    }
  }
`;
