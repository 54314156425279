import { EventForEventCollection } from 'app/typings';
import { Event } from 'app/typings/events';
import { useFeatureFlagsForExperiments } from 'app/shared/utils/useFeatureFlags';
// import { useExperimentFeatureFlags } from 'app/shared/utils/useExperimentFeatureFlags';

// All the cities with the following business owners as of Dec 8, 2023:
// - UK
// - US East
// - US Central
// - US West
//
// These are cached slugs
const citiesDisplayingEventGenres = [
  'atlanta',
  'austin',
  'baltimore',
  'bath',
  'birmingham',
  'boston',
  'boulder',
  'brighton',
  'bristol',
  'chicago',
  'dallas-fort-worth',
  'denver',
  'eastbay',
  'edinburgh',
  'houston',
  'kent-ct',
  'la',
  'london',
  'long-beach',
  'miami',
  'milwaukee',
  'nyc',
  'philadelphia',
  'portland-or',
  'sacramento',
  'san-diego',
  'san-francisco',
  'san-gabriel-valley',
  'seattle',
  'toronto',
  'vancouver',
  'washington',
];

// This function is for loading the feature flags ONCE on a page - any components in the page
// can then take the return value (eventGenresFeatureFlagEnabled) and pass it into
// useDisplayEventGenres or useDisplayEventGenresByEventId below to test if we should display
// genre-related code in a particular place for a particular city or cities (which might be in a
// shared compoment or a component called many times within a loop, so we don't want to load feature
// flags there, AND we might not know the city or cities until we get down to that component)
export const useEventGenresFeatureFlagEnabled = () => {
  const {
    featureFlagsEnabled,
    error: featureFlagsError,
    loading: featureFlagsLoading,
    // Temporarily passing no flags into this hook to avoid unnecessary GetFeatureFlags call to backend -
    // long-term we'll probably just remove all the event genres code since it sounds like we've decided
    // not to implement event genres after all - Joe T. 3/20/2024
  } = useFeatureFlagsForExperiments([]);

  const eventGenresFeatureFlagEnabledRaw = featureFlagsEnabled.eventGenres;
  const eventGenresFeatureFlagEnabled = !!(
    eventGenresFeatureFlagEnabledRaw &&
    !featureFlagsError &&
    !featureFlagsLoading
  );

  return eventGenresFeatureFlagEnabled;
};

interface UseDisplayEventGenresParams {
  eventGenresFeatureFlagEnabled?: boolean;
  citySlug?: string;
}

// This function is for testing if we should display genre-related code for a SINGLE CITY, given an
// arg indicating whether or not the relevant feature flag is enabled (eventGenresFeatureFlagEnabled),
// which should have been loaded once at the top of the page via useEventGenresFeatureFlagEnabled above
export const useDisplayEventGenres = ({
  eventGenresFeatureFlagEnabled,
  citySlug,
}: UseDisplayEventGenresParams) => {
  const displayEventGenres = !!(
    eventGenresFeatureFlagEnabled &&
    citySlug &&
    citiesDisplayingEventGenres.includes(citySlug)
  );

  return displayEventGenres;
};

interface UseDisplayEventGenresByEventIdParams {
  eventGenresFeatureFlagEnabled?: boolean;
  events?: (Event | EventForEventCollection)[];
}

// This function is for testing if we should display genre-related code for MULTIPLE CITIES, given an
// arg indicating whether or not the relevant feature flag is enabled (eventGenresFeatureFlagEnabled),
// which should have been loaded once at the top of the page via useEventGenresFeatureFlagEnabled above
export const useDisplayEventGenresByEventId = ({
  eventGenresFeatureFlagEnabled,
  events,
}: UseDisplayEventGenresByEventIdParams) => {
  const displayEventGenresByEventId: {} = [];

  if (events) {
    for (const event of events) {
      displayEventGenresByEventId[event.id] = !!(
        eventGenresFeatureFlagEnabled &&
        citiesDisplayingEventGenres.includes(event.city.cachedSlug)
      );
    }
  }

  return displayEventGenresByEventId;
};

interface useDisplayEventGenresWithExperimentParams {
  citySlug?: string;
}

// This function is for loading the feature flags AND tracking the experiment ONCE on a page - any
// components in the page can then take the return value (displayEventGenres) and test if we should
// display genre-related code in a particular place (which might be in a shared compoment or a component
// called many times within a loop, so we don't want to load feature flags there)
export const useDisplayEventGenresWithExperiment = ({
  citySlug,
}: useDisplayEventGenresWithExperimentParams) => {
  // const {
  //   featureFlagsEnabled,
  //   error: featureFlagsError,
  //   loading: featureFlagsLoading,
  // } = useExperimentFeatureFlags({
  //   experiments: [
  //     {
  //       featureFlag: 'event_genres',
  //       experimentFlag: 'event_genres_experiment_tracking',
  //       experimentName: 'Event Genres Experiment 2',
  //       shouldNotTrack: !(
  //         citySlug && citiesDisplayingEventGenres.includes(citySlug)
  //       ),
  //     },
  //   ],
  // });

  // Temporarily commented out the call to useExperimentFeatureFlags above to avoid unnecessary
  // GetFeatureFlags call to backend - long-term we'll probably just remove all the event genres code
  // since it sounds like we've decided not to implement event genres after all - Joe T. 3/20/2024
  const featureFlagsEnabled = { eventGenres: false };
  const featureFlagsError = undefined;
  const featureFlagsLoading = false;

  const eventGenresFeatureFlagEnabledRaw = featureFlagsEnabled.eventGenres;
  const displayEventGenres = !!(
    eventGenresFeatureFlagEnabledRaw &&
    !featureFlagsLoading &&
    !featureFlagsError &&
    citySlug &&
    citiesDisplayingEventGenres.includes(citySlug)
  );

  return displayEventGenres;
};
