import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import {
  Box,
  Icon,
  InputHelperText,
  InputProps,
  InputStatusIcon,
  TextInput,
  Typography,
} from 'app/fan/components/atoms';
import { ReactComponent as Lock } from 'icons/lock.svg';

interface Props extends InputProps {
  value?: string | number;
  helperText?: string | React.ReactNode;
  label: string;
  isDisabled?: boolean;
  displayInputValueResetButton?: boolean;
  onResetButtonClick?: () => void;
  labelColor?: string;
  isRequired?: boolean;
  'data-qaid'?: string;
}

export const Textfield: React.FC<Props> = ({
  onChange = () => {},
  helperText,
  hasError = false,
  label,
  isSuccessful = false,
  value = '',
  isDisabled = false,
  displayInputValueResetButton = false,
  onResetButtonClick = () => {},
  className,
  backgroundColor,
  labelColor,
  invertColors = false,
  color,
  name,
  isLocked = false,
  mb,
  isRequired,
  'data-qaid': qaId = 'text-field',
  ...props
}) => {
  const theme = useCurrentTheme();

  const displayResetButton =
    displayInputValueResetButton && !isSuccessful && !!value && !hasError;

  const inputStatusIconType = () => {
    if (hasError) {
      return 'ERROR';
    }
    if (displayResetButton) {
      return 'RESET';
    }
    if (isSuccessful) {
      return 'SUCCESS';
    }

    return null;
  };

  return (
    <Box
      position="relative"
      w="100%"
      flexDirection="column"
      alignItems="stretch"
      className={className}
      data-qaid={qaId}
      mb={mb}
    >
      <Typography
        lineHeight="100%"
        fontSize="fontSize50"
        fontWeight="semibold"
        textTransform="uppercase"
        color={hasError ? theme.colors.messaging.error.dark : labelColor}
        data-qaid={`${qaId}-label`}
        mb={2}
      >
        {label}
        {isRequired ? '*' : ''}
      </Typography>
      <Box flexDirection="row" alignItems="center">
        {!isLocked && (
          <InputStatusIcon
            onClickResetInput={onResetButtonClick}
            iconType={inputStatusIconType()}
            position="absolute"
            right={`${theme.ruler[4]}px`}
            invertColors={invertColors}
            data-qaid={`${qaId}-status-icon`}
          />
        )}
        {isLocked && (
          <Box position="absolute" right={`${theme.ruler[4]}px`}>
            <Icon
              size="sm"
              icon={Lock}
              strokeColor={theme.colors.primary.black.lighter}
              data-qaid={`${qaId}-lock-icon`}
            />
          </Box>
        )}
        <TextInput
          data-qaid={`${qaId}-input`}
          disabled={isDisabled}
          hasError={hasError}
          isSuccessful={isSuccessful}
          value={value}
          onChange={onChange}
          readOnly={isLocked}
          isLocked={isLocked}
          backgroundColor={backgroundColor}
          invertColors={invertColors}
          color={color}
          name={name}
          aria-required={isRequired}
          {...props}
        />
      </Box>
      {helperText && (
        <InputHelperText
          data-qaid={`${qaId}-helper-text`}
          isDisabled={isDisabled}
          hasError={hasError}
          isSuccessful={isSuccessful}
          invertColors={labelColor === theme.colors.primary.white.base}
        >
          {helperText}
        </InputHelperText>
      )}
    </Box>
  );
};
