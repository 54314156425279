import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'react-intl';

import {
  City,
  CityForClp,
  OnSignUpSuccess,
  RenderCompetitionOptIn,
} from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { useAuthFormContext, useMultiStepSignUpContext } from 'app/fan/context';
import { Box } from 'app/fan/components/atoms';
import {
  ModalDetailsSection,
  ProtectedByReCAPTCHA,
  SmsMarketingDisclaimer,
  TermsAndConditions,
} from 'app/fan/components/molecules';

import CompleteAccountForm from './CompleteAccountForm';
import CreateAccountForm from './CreateAccountForm';

interface Props {
  formContext: string;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
  city?: City | CityForClp;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  onSignUpSuccess?: OnSignUpSuccess;
  onClickSignIn?: () => void;
  renderCompetitionOptIn?: RenderCompetitionOptIn;
  testMode?: boolean;
  testResponse?: any;
}

export const AccountStep: React.FC<Props> = ({
  formContext,
  onClose,
  setIsOpen,
  userEmail,
  userFirstName,
  userLastName,
  city,
  onSignUpSuccess,
  onClickSignIn,
  renderCompetitionOptIn,
  testMode,
  testResponse,
}) => {
  const { formatMessage } = useIntl();
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { setNewUser } = useMultiStepSignUpContext();
  const { isCompleteSignUpFlow } = useAuthFormContext();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const { colors } = useCurrentTheme();

  const onSuccess: OnSignUpSuccess = signUpSuccessParams => {
    if (signUpSuccessParams) {
      onSignUpSuccess && onSignUpSuccess(signUpSuccessParams);
      if (signUpSuccessParams.isNewAccount) {
        trackAnalyticsEvent(
          'Account Sign Up Success',
          {
            email: signUpSuccessParams.user.email,
            formContext,
          },
          true
        );
      }
      setNewUser(signUpSuccessParams.user);
    }
  };

  const createAccountStepTitle = formatMessage({
    id: isCompleteSignUpFlow
      ? 'authentication.completeAccount.title'
      : 'shared.signUp',
  });
  const createAccountCta = formatMessage({
    id: isCompleteSignUpFlow
      ? 'authentication.completeAccount.submit'
      : 'shared.signUp',
  });

  const { isMobile } = useIsBreakpointSize();

  function onSkipAuth() {
    setIsOpen && setIsOpen(false);
    if (formContext !== 'auth-modal-sign-up') {
      trackAnalyticsEvent('Modal Closed By User', {
        modal: `${formContext}-complete-account`,
        is_mobile: isMobile,
        title: createAccountStepTitle,
        primary_cta_text: createAccountCta,
        secondary_cta_text: formatMessage({ id: 'shared.cancel' }),
      });
    }
    onClose && onClose();
  }

  const accountFormProps = {
    onSuccess,
    userEmail,
    userFirstName,
    userLastName,
    city,
    onCancel: onSkipAuth,
    onClickSignIn,
  };

  return (
    <>
      <Box flexDirection="column" gap={8}>
        {isCompleteSignUpFlow && (
          <CompleteAccountForm
            {...accountFormProps}
            formContext={`${formContext} complete account form`}
            data-qaid={`${formContext}-complete-account-form`}
          />
        )}
        {!isCompleteSignUpFlow && (
          <CreateAccountForm
            {...accountFormProps}
            reCaptchaRef={reCaptchaRef}
            onError={() => reCaptchaRef.current?.reset()}
            signUpHeader={createAccountStepTitle}
            renderCompetitionOptIn={renderCompetitionOptIn}
            formContext={`${formContext} create account form`}
            data-qaid="create-account-form"
            testMode={testMode}
            testResponse={testResponse}
          />
        )}
        <ModalDetailsSection
          as="div"
          detailText={
            <Box flexDirection="column" gap={4}>
              <TermsAndConditions color={colors.primary.black.base} />
              <ProtectedByReCAPTCHA />
              {isCompleteSignUpFlow && (
                <SmsMarketingDisclaimer
                  hasBackgroundColor={false}
                  color={colors.primary.black.base}
                  fontSize="fontSize75"
                />
              )}
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default AccountStep;
