import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PromoCodeWithCharges } from 'app/typings';
import { useAnalyticsContext } from 'app/shared/utils';
import {
  SetClearPromoCodeCallback,
  ValidatePromoCode,
  ValidatePromoCodeResponse,
} from 'app/fan/utils/checkout';

import { useValidatePromoCode } from './useValidatePromoCode';

export type PromoCodeStatus = 'NONE' | 'LOADING' | 'SUCCESS' | 'ERROR';

export const PromoCodeType = {
  GUARANTEEDINVITE: 'PromoCode::GuaranteedInvite',
  PERCENTAGEDISCOUNT: 'PromoCode::PercentageDiscount',
};

export interface PromoCodeValue {
  validatePromoCode: ValidatePromoCode;
  resetValidated: () => void;
  promoCodeStatus: PromoCodeStatus;
  promoCodeWithCharges?: PromoCodeWithCharges;
  clearPromoCode: () => void;
  promoCodeErrorMessage: string | null;
  wasValidatePromoCodeCalled: boolean;
  setClearPromoCodeCallback: SetClearPromoCodeCallback;
  urlParamCode: string | null;
}

function getPromoCodeStatus(
  validatePromoCodeResponse: ValidatePromoCodeResponse,
  validated: boolean
): PromoCodeStatus {
  if (!validated) {
    return 'NONE';
  }
  const { error, loading, data } = validatePromoCodeResponse;
  if (error) {
    return 'ERROR';
  }
  if (loading) {
    return 'LOADING';
  }
  if (data && data.promoCodeValidation.isValid) {
    return 'SUCCESS';
  }
  return 'ERROR';
}

function getPromoCodeErrorMessage(
  validatePromoCodeResponse: ValidatePromoCodeResponse
) {
  const { error } = validatePromoCodeResponse;
  if (!error) {
    return null;
  }
  if (
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].message
  ) {
    return error.graphQLErrors[0].message;
  } else if (error.message) {
    return error.message;
  }
  return null;
}

export const usePromoCode = (eventId?: string | number): PromoCodeValue => {
  const [searchParams] = useSearchParams();
  const [urlParamCode, setUrlParamCode] = useState<string | null>(
    searchParams.get('promo_code')
  );
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const validatePromoCodeData = useValidatePromoCode({
    eventId,
    onClearPromoCode: () => setUrlParamCode(null),
  });

  const {
    validatePromoCode,
    validatePromoCodeResponse,
    validated,
    resetValidated,
    clearPromoCode,
    setClearPromoCodeCallback,
  } = validatePromoCodeData;

  useEffect(() => {
    if (urlParamCode) {
      validatePromoCode(urlParamCode);
    }
  }, [urlParamCode]);

  const promoCodeStatus = getPromoCodeStatus(
    validatePromoCodeResponse,
    validated
  );
  const promoCodeErrorMessage = getPromoCodeErrorMessage(
    validatePromoCodeResponse
  );
  const promoCodeWithCharges = validated
    ? validatePromoCodeResponse?.data?.promoCodeValidation
    : undefined;
  const wasValidatePromoCodeCalled = validatePromoCodeResponse.called;

  useEffect(() => {
    if (promoCodeErrorMessage) {
      trackAnalyticsEvent('Promo Code Invalid', {
        inputValue: promoCodeWithCharges?.promoCode?.code,
      });
    }

    if (promoCodeStatus === 'SUCCESS') {
      trackAnalyticsEvent('Promo Code Valid', {
        inputValue: promoCodeWithCharges?.promoCode?.code,
      });
    }
  }, [promoCodeErrorMessage, promoCodeStatus]);

  const promoCodeValue = useMemo(
    () => ({
      urlParamCode,
      validatePromoCode,
      resetValidated,
      promoCodeStatus,
      promoCodeWithCharges,
      clearPromoCode,
      promoCodeErrorMessage,
      wasValidatePromoCodeCalled,
      setClearPromoCodeCallback,
    }),
    [
      urlParamCode,
      validatePromoCode,
      resetValidated,
      promoCodeStatus,
      promoCodeWithCharges,
      clearPromoCode,
      promoCodeErrorMessage,
      wasValidatePromoCodeCalled,
      setClearPromoCodeCallback,
    ]
  );

  return promoCodeValue;
};
