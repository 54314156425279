import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const ValidationSchema = (formatMessage: IntlShape['formatMessage']) =>
  Yup.object().shape({
    countryCode: Yup.string().trim(),
    mobile: Yup.string()
      .matches(/^[-\d().+]+( [-\d.]+)*$/, {
        message: formatMessage({
          id: 'form.validations.mobile.invalid',
        }),
      })
      .required(
        formatMessage({
          id: 'form.validations.mobile.required',
        })
      ),
  });
