import gql from 'graphql-tag';

export const GET_PROMO_CODE_VALIDATION = gql`
  query GetPromoCodeValidation($code: String!, $eventId: ID!) {
    promoCodeValidation(code: $code, eventId: $eventId) {
      isValid
      charges {
        amount
      }
      promoCode {
        id
        code
        validFrom
        expiresAt
        type
      }
    }
  }
`;
