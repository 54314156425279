import React, { useEffect } from 'react';
import { ApolloError } from 'apollo-client';
import { FormikProps } from 'formik';

import { User } from 'app/typings';
import { useAnalyticsContext } from 'app/shared/utils';
import { WithSpacingProps } from 'app/shared/components/styleUtils';
import { TOP_GLOBAL_COUNTRY_CODES } from 'app/fan/utils/countries';
import { Box, Spinner } from 'app/fan/components/atoms';
import {
  MobilePhoneField,
  SmsMarketingDisclaimer,
} from 'app/fan/components/molecules';

interface Props extends FormikProps<any>, WithSpacingProps {
  user?: User | null;
  apiError?: ApolloError;
  backgroundColor?: string;
  inputBackgroundColor?: string;
  inputColor?: string;
  label: string;
  formContext: string;
  isStandaloneForm: boolean;
  isLoaded?: boolean;
  setIsLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
  shouldDisplaySmsOptIn: boolean;
  inferredUserCountryCode?: string;
}

export const SmsSignUpInputWithDisclaimer: React.FC<Props> = ({
  user,
  backgroundColor,
  inputBackgroundColor,
  inputColor,
  formContext,
  isStandaloneForm,
  isLoaded,
  setIsLoaded,
  shouldDisplaySmsOptIn,
  inferredUserCountryCode,
  ...props
}) => {
  const { p, pt, pr, pb, pl, m, mt, mr, mb, ml, ...rest } = props;
  const spacingProps = { p, pt, pr, pb, pl, m, mt, mr, mb, ml };
  const { trackAnalyticsEvent } = useAnalyticsContext();

  useEffect(() => {
    if (isLoaded && !!shouldDisplaySmsOptIn) {
      trackAnalyticsEvent(
        'SMS Opt-In Displayed',
        {
          form_context: formContext,
          is_standalone_form: isStandaloneForm,
          default_country_code: inferredUserCountryCode,
          user_id: user?.id,
          email: user?.email?.toLowerCase().trim(),
        },
        true
      );
    }
  }, [isLoaded, shouldDisplaySmsOptIn]);

  useEffect(() => {
    if (!!inferredUserCountryCode && setIsLoaded) {
      setIsLoaded(true);
    }
  }, [inferredUserCountryCode]);

  if (!shouldDisplaySmsOptIn) {
    return null;
  }

  if (!isLoaded) {
    return (
      <Box justifyContent="center" data-qaid="sms-sign-up-loading">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box
      flexDirection="column"
      gap={2}
      {...spacingProps}
      data-qaid="sms-sign-up-input"
    >
      <MobilePhoneField
        {...rest}
        data-qaid="mobile-phone"
        backgroundColor={backgroundColor}
        inputBackgroundColor={inputBackgroundColor}
        color={inputColor}
        countryCodes={TOP_GLOBAL_COUNTRY_CODES}
        selectedCountryCode={inferredUserCountryCode}
      />
      <SmsMarketingDisclaimer hasBackgroundColor={!!backgroundColor} />
    </Box>
  );
};
