import gql from 'graphql-tag';

export const CONFIRM_PAYMENT_INTENT = gql`
  mutation confirmPaymentIntent(
    $paymentIntentId: String
    $billingRegion: String!
  ) {
    confirmPaymentIntent(
      paymentIntentId: $paymentIntentId
      billingRegion: $billingRegion
    ) {
      success
      paymentIntentId
      user {
        id
        isGuest
        email
      }
    }
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent(
    $paymentMethodId: String
    $amount: Int
    $currency: String
    $billingRegion: String!
    $eventId: Int!
    $storePaymentMethod: Boolean
    $numTickets: Int
    $isVirtualWallet: Boolean
    $isGuestCheckout: Boolean
    $optinGuestMarketing: Boolean
    $email: String
    $firstName: String
    $lastName: String
  ) {
    createPaymentIntent(
      paymentMethodId: $paymentMethodId
      amount: $amount
      currency: $currency
      billingRegion: $billingRegion
      eventId: $eventId
      storePaymentMethod: $storePaymentMethod
      numTickets: $numTickets
      isVirtualWallet: $isVirtualWallet
      isGuestCheckout: $isGuestCheckout
      optinGuestMarketing: $optinGuestMarketing
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      requiresAction
      paymentIntentId
      paymentIntentClientSecret
      user {
        id
        isGuest
        email
      }
    }
  }
`;
