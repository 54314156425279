import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { AuthContext } from 'app/shared/context/Auth';
import { withLocaleMessages } from 'app/shared/hoc';
import { Box } from 'app/fan/components/atoms';
import { SmsSignUpForm } from 'app/fan/components/organisms/SmsSignUpForm';

const SmsStep: React.FC<{
  onClose?: () => void;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
  formContext: string;
  title?: string;
  userFirstName?: string;
  userLastName?: string;
}> = ({ onClose, setIsOpen, onSuccess, formContext, title, ...props }) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useIsBreakpointSize();
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { signIn } = useContext(AuthContext);
  const theme = useCurrentTheme();
  const smsStepTitle = title || formatMessage({ id: 'smsSignUp.title' });

  function onSkipSms() {
    setIsOpen && setIsOpen(false);
    if (formContext !== 'auth-modal-sign-up') {
      trackAnalyticsEvent('Modal Closed By User', {
        modal: `${formContext}-sms-sign-up`,
        is_mobile: isMobile,
        title: smsStepTitle,
        primary_cta_text: formatMessage({ id: 'shared.signUpForSms' }),
        secondary_cta_text: formatMessage({ id: 'shared.skipForNow' }),
      });
    }
    onClose && onClose();
    signIn();
  }

  return (
    <Box flexDirection="column" gap={5}>
      <SmsSignUpForm
        data-qaid={`${formContext}-sms-sign-up`}
        formContext={`${formContext}-sms-sign-up`}
        backgroundColor={theme.colors.primary.white.base}
        title={smsStepTitle}
        isSubmitButtonFullWidth={true}
        onSuccess={onSuccess}
        secondaryCtaDataQaid="sms-sign-up-skip"
        secondaryCtaText={formatMessage({ id: 'shared.skipForNow' })}
        onClickSecondaryCta={onSkipSms}
        description={formatMessage({ id: 'smsSignUp.description' })}
        {...props}
      />
    </Box>
  );
};

export default withLocaleMessages(SmsStep, 'fan');
