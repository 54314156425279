import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Box, BoxProps, Typography } from 'app/fan/components/atoms';

export interface ItemTagProps extends BoxProps {
  tag: string;
}

export const ItemTag: React.FC<ItemTagProps> = ({
  tag,
  color,
  backgroundColor = 'none',
  borderRadius = '100px',
  b = 'none',
  ...props
}) => {
  const { letterSpacings } = useCurrentTheme();
  const fontSize = tag.length > 21 ? 'fontSize25' : 'fontSize50';

  return (
    <Box
      color={color}
      backgroundColor={backgroundColor}
      alignItems="center"
      gap={1}
      borderRadius={borderRadius}
      b={b}
      data-qaid="item-tag"
      {...props}
    >
      <Typography
        as="span"
        fontSize={fontSize}
        fontWeight="semibold"
        color={color}
        lineHeight="120%"
        textTransform="uppercase"
        letterSpacing={letterSpacings.uppercase}
        textWrap="balance"
        textAlign="center"
      >
        {tag}
      </Typography>
    </Box>
  );
};
