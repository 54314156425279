import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { OnSignUpSuccess } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { CREATE_FACEBOOK_SESSION } from 'app/shared/graphql/sessions/mutations';
import { useCityContext } from 'app/shared/context/City';
import { useAuthFormContext } from 'app/fan/context';

import {
  ThirdPartyAuthButton,
  ThirdPartyAuthMode,
} from './ThirdPartyAuthButton';

const facebookBlue = '#1877f2';

interface Props {
  onSuccess: OnSignUpSuccess;
  onError?: (e: any) => void;
  mode: ThirdPartyAuthMode;
  isGuestCheckoutOrderComplete?: boolean;
  testMode?: boolean;
  testResponse?: any;
  matchingEmail?: string;
}

export const FacebookLoginButton: React.FC<Props> = ({
  onSuccess,
  onError,
  mode,
  isGuestCheckoutOrderComplete = false,
  testMode,
  testResponse,
  matchingEmail,
}) => {
  const { lastViewedCity } = useCityContext();
  const [loginUser, { data }] = useMutation(CREATE_FACEBOOK_SESSION);
  const theme = useCurrentTheme();
  const [authType, setAuthType] = useState('');
  const { setApiError, setThirdPartyAuthProvider } = useAuthFormContext();
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const isCityOrEventPage =
    window.location.pathname.includes('/cities/') ||
    window.location.pathname.includes('/events/');

  const currentPageCitySlug = isCityOrEventPage
    ? lastViewedCity?.cachedSlug
    : undefined;

  const authOnPlatform = async (fbToken: string) => {
    if (!fbToken) {
      return;
    }
    setThirdPartyAuthProvider('Facebook');

    try {
      await loginUser({
        variables: {
          fbToken,
          citySlug: currentPageCitySlug,
          matchingEmail,
          isGuestCheckoutOrderComplete,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        setApiError(e);
        setAuthType('rerequest');
        onError && onError(e);
      }
    }
  };

  const onFacebookAuth = (response: any) => {
    authOnPlatform(response.accessToken);
  };

  const returnTestResponse = () => {
    onFacebookAuth(testResponse);
  };

  if (data && data.createFacebookSession.sessionId) {
    onSuccess({ user: data.createFacebookSession.user });
  }
  const dataQaid =
    mode === ThirdPartyAuthMode.SIGN_IN
      ? 'facebook-login-button'
      : 'facebook-signup-button';

  useEffect(() => {
    trackAnalyticsEvent('Facebook Oauth Displayed', { oauth: dataQaid }, true);
  }, []);

  // You might thinnnnnk you can remove the sssss ript tag below but this will break a lot of tests
  // The facebook login button expects a script tag to exist so it can inject another script tag
  // without it it is not able to inject the additional script tag
  return (
    <>
      <script type="text/javascript"></script>
      <FacebookLogin
        appId={process.env.FACEBOOK_APP_ID}
        callback={onFacebookAuth}
        isMobile={false}
        disableMobileRedirect={true}
        authType={authType}
        version="16.0"
        render={(renderProps: any) => (
          <ThirdPartyAuthButton
            fullWidth={true}
            color={theme.colors.primary.white.lightest}
            backgroundColor={facebookBlue}
            borderColor={facebookBlue}
            data-qaid={dataQaid}
            onClick={testMode ? returnTestResponse : renderProps.onClick}
            provider="Facebook"
          />
        )}
      />
    </>
  );
};

export default FacebookLoginButton;
