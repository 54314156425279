import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { VisaLogo } from 'app/shared/components/atoms';
import { Box, Icon, Typography } from 'app/fan/components/atoms';
import { ItemTag, ItemTagProps } from 'app/fan/components/molecules/ItemTag';
import { ReactComponent as ArrowRightIcon } from 'icons/arrow-right-diamond.svg';

interface CardContentsProps {
  title: string;
  bodyText?: string | React.ReactElement;
  dateMonth?: string; // e.g. 'Jan'
  dateDay?: string; // e.g. '6'
  dateWeekday?: string; // e.g. 'Sat'
  itemTagsProps?: ItemTagProps[];
  showArrowButton?: boolean;
}

export const CardContents: React.FC<CardContentsProps> = ({
  title,
  bodyText,
  dateMonth,
  dateDay,
  dateWeekday,
  itemTagsProps,
  showArrowButton,
}) => {
  const { borderRadius, colors, fonts, letterSpacings } = useCurrentTheme();
  const { formatMessage } = useIntl();
  const { isDesktop } = useIsBreakpointSize();

  return (
    <Box
      flexDirection="row"
      flex={1}
      h="100%"
      data-qaid="compact-event-card-contents"
      borderRadius={borderRadius.card}
      justifyContent="space-between"
      alignItems="center"
      gap={5}
      pr={5}
      minWidth="295px"
    >
      {dateMonth && dateDay && dateWeekday && (
        <Box
          flexDirection="column"
          gap={1}
          justifyContent="center"
          h="100%"
          backgroundColor={colors.accent.primaryAccent.base}
          py={4}
          borderRadiusBottomLeft="2px"
          borderRadiusTopLeft="2px"
          minWidth={isDesktop ? '80px' : '70px'}
        >
          <Typography
            as="span"
            fontFamily={fonts.title}
            textAlign="center"
            fontSize="fontSize75"
            textTransform="uppercase"
            letterSpacing={letterSpacings.default}
            lineHeight="100%"
          >
            {dateWeekday}
          </Typography>
          <Typography
            as="span"
            fontFamily={fonts.title}
            textAlign="center"
            textTransform="uppercase"
            letterSpacing={letterSpacings.default}
            fontSize="fontSize75"
            lineHeight="100%"
          >
            {dateMonth}
          </Typography>
          <Typography
            as="span"
            fontFamily={fonts.title}
            textAlign="center"
            textTransform="uppercase"
            letterSpacing={letterSpacings.default}
            fontSize="fontSize400"
            lineHeight="100%"
          >
            {dateDay}
          </Typography>
        </Box>
      )}
      <Box flexDirection="column" flex={1} justifyContent="center" gap={3}>
        <Typography
          color={colors.primary.black.base}
          fontSize="fontSize300"
          fontWeight="bold"
          lineHeight="120%"
          display="block"
        >
          {title}
        </Typography>
        {bodyText && (
          <Typography
            display="block"
            color={colors.primary.black.base}
            fontSize="fontSize75"
            fontWeight="regular"
            lineHeight="100%"
          >
            {bodyText}
          </Typography>
        )}
        {(itemTagsProps?.length || 0) > 0 && (
          <Box gap={1} flexDirection="row" flexWrap="wrap">
            {(itemTagsProps || []).map(itemTagProps => {
              if (
                itemTagProps.tag == formatMessage({ id: 'shared.visaPresale' })
              ) {
                return (
                  <Box
                    maxWidth="fit-content"
                    key={itemTagProps.tag}
                    gap={2}
                    alignItems="center"
                  >
                    <VisaLogo />
                    <Typography
                      fontWeight="semibold"
                      textTransform="uppercase"
                      fontSize="fontSize50"
                      lineHeight="100%"
                    >
                      {formatMessage({ id: 'shared.presale' })}
                    </Typography>
                  </Box>
                );
              }

              return (
                <Box maxWidth="fit-content" key={itemTagProps.tag}>
                  <ItemTag {...itemTagProps} px={3} py={1} />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      {showArrowButton && (
        <Icon fillColor="none" size="lg" icon={ArrowRightIcon} />
      )}
    </Box>
  );
};
