import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { useVisaAuth } from 'app/shared/utils/visa';
import { Filter } from 'app/fan/components/molecules/Filters';

export interface FilterableEventListContextValue {
  appliedFilterKeys: string[];
  setAppliedFilterKeys: Dispatch<SetStateAction<string[]>>;
  availableFilters: Filter[];
  hasInteractedWithFilters: boolean;
  setHasInteractedWithFilters: Dispatch<SetStateAction<boolean>>;
  nonFilterSearchParams: {
    key: any;
    value: any;
  }[];
}

const calculateAvailableFilters = (
  canBypassVisaValidation: boolean,
  visaPreSaleEligible?: boolean
): Filter[] => {
  let availableFilters = [
    {
      key: 'indoor',
      displayNameIntlId: 'city.filter.indoor',
    },
    {
      key: 'outdoor',
      displayNameIntlId: 'city.filter.outdoor',
    },
    {
      key: 'byob',
      displayNameIntlId: 'city.filter.byob',
    },
  ];

  if (visaPreSaleEligible || canBypassVisaValidation) {
    // Insert Visa Presale filter as second filter option
    availableFilters.splice(1, 0, {
      displayNameIntlId: 'shared.visaPresale',
      key: 'visa-presale',
    });
  }

  return availableFilters;
};

const getInitialFilterState = (
  availableFilters: Filter[],
  searchParams: any
) => {
  return availableFilters
    .filter((filter: Filter) => searchParams.get(filter.key))
    .map((filter: Filter) => filter.key);
};

const getNonFilterSearchParams = (
  availableFilters: Filter[],
  searchParams: any
) => {
  let nonFilterSearchParams = [];
  for (const [key, value] of searchParams.entries()) {
    if (
      availableFilters.filter((filter: Filter) => filter.key === key).length ===
      0
    ) {
      nonFilterSearchParams.push({ key, value });
    }
  }
  return nonFilterSearchParams;
};

export const FilterableEventListContext = createContext<FilterableEventListContextValue | null>(
  null
);

interface FilterableEventListContextProviderProps {}
export const FilterableEventListContextProvider: React.FC<FilterableEventListContextProviderProps> = ({
  children,
}) => {
  const { visaPreSaleEligible, canBypassVisaValidation } = useVisaAuth({
    skipLoadCardRedemptionsStatus: true,
  });

  const availableFilters = calculateAvailableFilters(
    canBypassVisaValidation,
    visaPreSaleEligible
  );
  const [searchParams] = useSearchParams();
  const initialFilterState = getInitialFilterState(
    availableFilters,
    searchParams
  );
  const nonFilterSearchParams = getNonFilterSearchParams(
    availableFilters,
    searchParams
  );
  const [appliedFilterKeys, setAppliedFilterKeys] = useState<string[]>(
    initialFilterState
  );

  const [hasInteractedWithFilters, setHasInteractedWithFilters] = useState(
    false
  );

  const authFormContextValue = useMemo(
    () => ({
      appliedFilterKeys,
      setAppliedFilterKeys,
      availableFilters,
      hasInteractedWithFilters,
      setHasInteractedWithFilters,
      nonFilterSearchParams,
    }),
    [
      appliedFilterKeys,
      availableFilters,
      hasInteractedWithFilters,
      setHasInteractedWithFilters,
      nonFilterSearchParams,
    ]
  );

  return (
    <FilterableEventListContext.Provider value={authFormContextValue}>
      {children}
    </FilterableEventListContext.Provider>
  );
};

export const useFilterableEventListContext = () => {
  const context = React.useContext(FilterableEventListContext);
  if (context === null) {
    throw new Error(
      'useFilterableEventListContext must be used within a FilterableEventListContextProvider'
    );
  }
  return context;
};
