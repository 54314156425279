import { useIntl } from 'react-intl';

import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import {
  CURATOR_OWNER_NONCOMMERCIAL,
  DISCOVERY_EVENT,
} from 'app/shared/utils/events';

interface EventForIncludeBookingFeeInDisplayPrice {
  eventOrganizedAs: string;
  type?: string;
  bookingFee?: number | null;
  onPresale?: boolean;
  city: {
    id: number;
    displayBookingFee?: boolean;
    cachedSlug: string;
    country: {
      id: number;
      displayBookingFee?: boolean;
      currencySymbol?: string;
    };
  };
}

const newYorkStateCities = [
  'buffalo',
  'long-island',
  'nyc',
  'rochester',
  'syracuse',
];

function excludeBookingFeeInDisplayPriceForEventsInCity(
  event: EventForIncludeBookingFeeInDisplayPrice
): boolean {
  return newYorkStateCities.includes(event.city?.cachedSlug);
}

function eventCanHaveBookingFee(
  event?: EventForIncludeBookingFeeInDisplayPrice
): boolean {
  return !!(
    event &&
    event.type === DISCOVERY_EVENT &&
    event.eventOrganizedAs !== CURATOR_OWNER_NONCOMMERCIAL
  );
}

function eventHasBookingFee(
  event?: EventForIncludeBookingFeeInDisplayPrice
): boolean {
  // Note that !!0 evaluates to false, as do !!null and !!undefined
  return !!(eventCanHaveBookingFee(event) && !!event?.bookingFee);
}

function bookingFeeForDisplay(event?: EventForIncludeBookingFeeInDisplayPrice) {
  return (
    event?.bookingFee &&
    event.city.country.currencySymbol &&
    currencyFormatter(event.bookingFee, event.city.country.currencySymbol)
  );
}

export function includeBookingFeeInDisplayPrice(
  event: EventForIncludeBookingFeeInDisplayPrice
): boolean {
  if (
    !eventCanHaveBookingFee(event) ||
    excludeBookingFeeInDisplayPriceForEventsInCity(event)
  ) {
    return false;
  }

  if (
    event.city?.displayBookingFee !== undefined &&
    event.city?.displayBookingFee !== null
  ) {
    return !!event.city?.displayBookingFee;
  }

  return !!event.city?.country?.displayBookingFee;
}

export function useBookingFeeMessage({
  event,
  userIsVisaEligible,
  canBypassVisaValidation,
}: {
  event: EventForIncludeBookingFeeInDisplayPrice;
  userIsVisaEligible: boolean;
  canBypassVisaValidation: boolean;
}) {
  const { formatMessage } = useIntl();

  function getBookingFeeMessage() {
    if (!eventHasBookingFee(event)) {
      return null;
    }

    const feeIncludedInDisplayPrice = includeBookingFeeInDisplayPrice(event);

    const intlId = feeIncludedInDisplayPrice
      ? 'event.eventCTA.bookingFeeIncludedInPrice'
      : excludeBookingFeeInDisplayPriceForEventsInCity(event)
      ? 'event.eventCTA.bookingFeeOfXAddedAtCheckout'
      : userIsVisaEligible || (event.onPresale && canBypassVisaValidation)
      ? 'event.eventCTA.bookingFeeAddedAtCheckoutToEachTicket'
      : 'event.eventCTA.bookingFeeAddedAtCheckout';

    return formatMessage(
      {
        id: intlId,
      },
      {
        bookingFee: bookingFeeForDisplay(event),
      }
    );
  }

  return getBookingFeeMessage();
}
