import gql from 'graphql-tag';

export const GET_EVENT_ATTENDEE = gql`
  query GetEventAttendee(
    $userId: ID
    $eventId: ID
    $cachedSlug: String
    $id: Int
    $visibleToUser: Boolean
  ) {
    eventAttendee(
      userId: $userId
      eventId: $eventId
      cachedSlug: $cachedSlug
      id: $id
      visibleToUser: $visibleToUser
    ) {
      id
      currentState
      accepted
      source
      skipPayment
      totalTicketCost
      hasAppliedPromoCode
      visibleToUser
      occasion
      occasionDetails
      tickets {
        id
        firstName
        lastName
        email
        primary
      }
    }
  }
`;

// Used on the checkout page's 'Order Complete' stage
export const GET_EVENT_ATTENDEE_FOR_EXPRESS_GUEST_CHECKOUT = gql`
  query GetEventAttendee($id: Int) {
    eventAttendee(id: $id) {
      id
      user {
        id
        email
        firstName
        lastName
        isGuest
        lastAuthMethod
        resetPasswordToken
      }
    }
  }
`;
