import React from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { WithSpacingProps } from 'app/shared/components/styleUtils';
import { IconButton } from 'app/shared/components/molecules/IconButton';
import { usePortal } from 'app/fan/utils/usePortal';
import {
  Box,
  PortalContainer,
  PortalTransitionProps,
  PortalUnderlay,
} from 'app/fan/components/atoms';
import { ReactComponent as Close } from 'icons/close.svg';

export interface DrawerProps extends WithSpacingProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transitionSpeed?: number;
  children: React.ReactNode;
  onClose?: () => void;
}

const StyledBoxDrawer = styled(Box)<PortalTransitionProps>`
  ${({ isShowing, transitionSpeed }) => css`
    transform: ${isShowing ? 'translateY(0)' : 'translateY(100%)'};
    transition: transform ${transitionSpeed}ms ease;
  `}
`;

export const Drawer = ({
  children,
  isOpen,
  setIsOpen,
  p = 3,
  pt = 10,
  transitionSpeed = 300,
  onClose,
  ...props
}: DrawerProps) => {
  const { colors, zIndex } = useCurrentTheme();

  function closeDrawer() {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  }
  const {
    portalRootRef: drawerPortalRootRef,
    isUnmounted,
    isShowing,
  } = usePortal({
    isOpen,
    close: closeDrawer,
    transitionSpeed,
  });

  if (isUnmounted) {
    return null;
  }

  // Create a portal to render the Drawer outside of the dom structure
  return createPortal(
    <PortalContainer
      data-qaid="drawer"
      aria-hidden={isOpen ? 'false' : 'true'}
      zIndex={zIndex.drawer}
    >
      <StyledBoxDrawer
        isShowing={isShowing}
        {...props}
        transitionSpeed={transitionSpeed}
        p={p}
        pt={pt}
        pb={16}
        w="100vw"
        h="80vh"
        position="fixed"
        bottom="0px"
        left="0px"
        zIndex={zIndex.drawer}
        boxShadow={`0 0 15px 0 solid ${colors.primary.black.base}`}
        overflow="auto"
        backgroundColor={colors.primary.white.base}
      >
        <IconButton
          aria-label="close drawer"
          icon={Close}
          onClick={closeDrawer}
          backgroundColor="unset"
          strokeColor={colors.accent.primaryAccent.base}
          fillColor="none"
          size="md"
          p={3}
          m={0}
          position="absolute"
          top="0px"
          right="0px"
        />
        {children}
      </StyledBoxDrawer>
      <PortalUnderlay
        onClick={closeDrawer}
        isShowing={isShowing}
        transitionSpeed={transitionSpeed}
      />
    </PortalContainer>,
    drawerPortalRootRef.current
  );
};
