import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { IconButton } from 'app/shared/components/molecules/IconButton';
import { Box } from 'app/fan/components/atoms';
import { ReactComponent as Close } from 'icons/close.svg';

interface Props {
  disableClose: boolean;
  onClick: () => void;
}

export const ModalCloseButton = ({ disableClose, onClick }: Props) => {
  const { colors } = useCurrentTheme();

  if (disableClose) {
    return null;
  }

  return (
    <Box justifyContent="flex-end">
      <IconButton
        data-qaid="close-modal-button"
        aria-label="close modal"
        icon={Close}
        onClick={onClick}
        backgroundColor="unset"
        strokeColor={colors.accent.primaryAccent.base}
        fillColor="none"
        size="md"
        position="relative"
        p={0}
        m={0}
      />
    </Box>
  );
};
