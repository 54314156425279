import { IntlShape } from 'react-intl';

import { Neighborhood } from 'app/typings';

const secretLocationText = (formatMessage: IntlShape['formatMessage']) =>
  formatMessage({
    id: 'shared.secretLocation',
  });

interface EventForNeighborhoodOrSecretLocation {
  isVenueConfirmed: boolean;
  venue?: {
    neighborhood?: Neighborhood;
  };
  neighborhood?: Neighborhood;
}

export const neighborhoodOrSecretLocation = (
  event: EventForNeighborhoodOrSecretLocation,
  formatMessage: IntlShape['formatMessage']
) => {
  const secretLocation = secretLocationText(formatMessage);

  return event.isVenueConfirmed && !!event.venue
    ? event.venue.neighborhood?.title || secretLocation
    : event.neighborhood?.title || secretLocation;
};

export const isSecretLocation = (
  event: EventForNeighborhoodOrSecretLocation,
  formatMessage: IntlShape['formatMessage']
) =>
  !!(
    neighborhoodOrSecretLocation(event, formatMessage) ==
    secretLocationText(formatMessage)
  );
