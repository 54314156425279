import gql from 'graphql-tag';

// Used to update password on user profile edit page
export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      success
    }
  }
`;

export const CREATE_GUEST_USER_ACCOUNT = gql`
  mutation createGuestUserAccount(
    $email: String!
    $firstName: String
    $lastName: String
    $optInFanMarketingEmails: Boolean
  ) {
    createGuestUserAccount(
      email: $email
      firstName: $firstName
      lastName: $lastName
      optInFanMarketingEmails: $optInFanMarketingEmails
    ) {
      success
      user {
        id
        email
        firstName
        lastName
        isGuest
      }
    }
  }
`;

export const CLAIM_GUEST_USER_ACCOUNT = gql`
  mutation claimGuestUserAccount(
    $claimUserToken: String!
    $birthYear: String
    $firstName: String
    $lastName: String
    $mobile: MobileAttributes
    $optInFanMarketingSmses: Boolean
    $password: String!
    $isCheckout: Boolean!
  ) {
    claimGuestUserAccount(
      claimUserToken: $claimUserToken
      birthYear: $birthYear
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
      optInFanMarketingSmses: $optInFanMarketingSmses
      password: $password
      isCheckout: $isCheckout
    ) {
      success
      user {
        id
        email
        firstName
        lastName
        isGuest
        mobile {
          countryCode
          countryCodeIso3166
          numberBody
        }
        optInFanMarketingSmses
      }
    }
  }
`;

export const UPDATE_HOME_CITY = gql`
  mutation updateHomeCity($homeCityId: String!) {
    updateUserProfile(homeCityId: $homeCityId) {
      success
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $email: String
    $firstName: String
    $lastName: String
    $homeCityId: String
    $birthYear: String
    $mobile: MobileAttributes
    $optInFanMarketingSmses: Boolean
    $optOutFanMarketingEmails: Boolean
  ) {
    updateUserProfile(
      email: $email
      firstName: $firstName
      lastName: $lastName
      homeCityId: $homeCityId
      birthYear: $birthYear
      mobile: $mobile
      optInFanMarketingSmses: $optInFanMarketingSmses
      optOutFanMarketingEmails: $optOutFanMarketingEmails
    ) {
      success
      user {
        id
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
      }
    }
  }
`;

export const UPDATE_USER_HOW_HEARD_ABOUT = gql`
  mutation updateUserHowHeardAbout($howHeardAbout: String!) {
    updateUserProfile(howHeardAbout: $howHeardAbout) {
      success
    }
  }
`;
