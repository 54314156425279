import { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';

import { PaymentMethodsApiResponse, User } from 'app/typings';
import { GET_PAYMENT_METHODS } from 'app/fan/graphql/paymentMethods/queries';

export const useSavedCards = (user: User | null) => {
  const [loadSavedCards, { loading, data }] = useLazyQuery<
    PaymentMethodsApiResponse
  >(GET_PAYMENT_METHODS);

  useEffect(() => {
    if (user) {
      loadSavedCards();
    }
  }, [user, loadSavedCards]);

  return {
    savedCards: data && data.paymentMethods ? data.paymentMethods.cards : [],
    savedCardsLoading: loading,
  };
};
