import React from 'react';

import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { NavigationButtonProps } from 'app/shared/components/molecules/NavigationButton';
import { Box } from 'app/fan/components/atoms';
import {
  ActionButton,
  ActionButtonProps,
} from 'app/fan/components/molecules/ActionButton';

export interface ButtonsSectionProps {
  primaryCtaText?: string;
  primaryCtaType?: 'button' | 'submit' | 'reset';
  onClickPrimaryCta?: () => void;
  isPrimaryCtaFullWidth?: boolean;
  isPrimaryCtaLoading?: boolean;
  isPrimaryCtaDisabled?: boolean;
  primaryCtaDataQaid?: string;
  secondaryCtaText?: string;
  onClickSecondaryCta?: () => void;
  isSecondaryCtaDisabled?: boolean;
  secondaryCtaDataQaid?: string;
  mobileFlexDirection?: 'row-reverse' | 'column';
  SecondaryButtonComponent?:
    | React.ElementType<ActionButtonProps>
    | React.ElementType<NavigationButtonProps>;
  secondaryButtonHref?: string;
}

export const ModalButtonsSection: React.FC<ButtonsSectionProps> = ({
  primaryCtaText,
  primaryCtaType = 'button',
  onClickPrimaryCta,
  isPrimaryCtaFullWidth = false,
  isPrimaryCtaLoading = false,
  isPrimaryCtaDisabled = false,
  primaryCtaDataQaid,
  secondaryCtaText,
  onClickSecondaryCta,
  isSecondaryCtaDisabled = false,
  secondaryCtaDataQaid,
  mobileFlexDirection = 'row-reverse',
  SecondaryButtonComponent = ActionButton,
  secondaryButtonHref,
}) => {
  const { isMobile } = useIsBreakpointSize();

  if (!primaryCtaText) {
    return null;
  }

  const width = isPrimaryCtaFullWidth || !!secondaryCtaText ? '100%' : '50%';

  const secondaryCtaProps =
    SecondaryButtonComponent === ActionButton
      ? {
          onClick: onClickSecondaryCta,
          isLoading: false,
          disabled: isSecondaryCtaDisabled,
        }
      : {
          href: secondaryButtonHref,
        };

  return (
    <Box
      flexDirection={isMobile ? mobileFlexDirection : 'row-reverse'}
      alignItems="center"
      justifyContent={secondaryCtaText ? 'center' : 'flex-start'}
      gap={4}
      flexWrap="wrap"
      w={width}
    >
      <ActionButton
        flex="1 1 calc(50% - 8px)"
        data-qaid={primaryCtaDataQaid}
        type={primaryCtaType}
        variant="primary"
        isLoading={isPrimaryCtaLoading}
        disabled={isPrimaryCtaDisabled}
        onClick={onClickPrimaryCta}
        fullWidth={isPrimaryCtaFullWidth || !!secondaryCtaText}
      >
        {primaryCtaText}
      </ActionButton>
      {secondaryCtaText && (
        <SecondaryButtonComponent
          flex="1 1 calc(50% - 8px)"
          data-qaid={secondaryCtaDataQaid}
          variant="secondary"
          type="button"
          fullWidth
          {...secondaryCtaProps}
        >
          {secondaryCtaText}
        </SecondaryButtonComponent>
      )}
    </Box>
  );
};
