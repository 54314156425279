import React from 'react';

import { OnSignUpSuccess } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useAuthFormContext } from 'app/fan/context';
import { Box } from 'app/fan/components/atoms';
import { AlternativesDivider } from 'app/fan/components/molecules';

import { AppleLoginButton } from './AppleLoginButton';
import { FacebookLoginButton } from './FacebookLoginButton';
import { GoogleLoginButton } from './GoogleLoginButton';
import { ThirdPartyAuthMode } from './ThirdPartyAuthButton';

export { ThirdPartyAuthMode };

export const ThirdPartyAuth: React.FC<{
  mode: ThirdPartyAuthMode;
  onSuccess: OnSignUpSuccess;
  onError?: (e: any) => void;
  testMode?: boolean;
  testResponse?: any;
}> = ({ testMode, testResponse, ...props }) => {
  const { isCompleteSignUpFlow } = useAuthFormContext();
  const { colors } = useCurrentTheme();

  if (isCompleteSignUpFlow || (testMode && !testResponse)) {
    return null;
  }

  return (
    <>
      <AlternativesDivider
        color={colors.primary.black.base}
        textColor={colors.primary.black.base}
        backgroundColor={colors.primary.white.base}
      />
      <Box gap={2} flexDirection="column">
        <AppleLoginButton {...props} />
        {process.env.GOOGLE_CLIENT_ID && <GoogleLoginButton {...props} />}
        <FacebookLoginButton
          {...props}
          testMode={testMode}
          testResponse={testResponse}
        />
      </Box>
    </>
  );
};
