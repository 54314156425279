import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { Box, Typography } from 'app/fan/components/atoms';
import { ItemTagProps } from 'app/fan/components/molecules/ItemTag';

import { CardContents } from './CardContents';

export interface CompactItemCardProps {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  title: string;
  bodyText?: string | React.ReactElement;
  dateMonth?: string; // e.g. 'Jan'
  dateDay?: string; // e.g. '6'
  dateWeekday?: string; // e.g. 'Sat'
  itemTagsProps?: ItemTagProps[];
  showArrowButton?: boolean;
  bottomBarLabel?: string;
  bottomBarText?: string;
}

export const CompactItemCard: React.FC<CompactItemCardProps> = ({
  backgroundColor,
  hoverBackgroundColor,
  title,
  bodyText,
  dateMonth,
  dateDay,
  dateWeekday,
  itemTagsProps,
  showArrowButton = true,
  bottomBarLabel,
  bottomBarText,
}) => {
  const { borderRadius, colors } = useCurrentTheme();
  const { isMobile } = useIsBreakpointSize();
  const cardHeight =
    bottomBarLabel && bottomBarText ? (isMobile ? '145px' : '165px') : '100%';

  const bottomBarBackgroundColor = colors.primary.black.light;
  const cardBackgroundColor = backgroundColor || colors.primary.white.base;
  const cardHoverBackgroundColor =
    hoverBackgroundColor || colors.primary.white.lightest;

  if (bottomBarLabel && bottomBarText) {
    return (
      <Box
        h={cardHeight}
        w="100%"
        minWidth="295px"
        borderRadius={borderRadius.card}
        backgroundColor={cardBackgroundColor}
        hoverBackgroundColor={cardHoverBackgroundColor}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        data-qaid="compact-item-card"
        gap={5}
      >
        <Box
          h="100%"
          w="100%"
          borderRadiusTopLeft={borderRadius.card}
          borderRadiusTopRight={borderRadius.card}
          px={4}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <CardContents
            bodyText={bodyText}
            dateMonth={dateMonth}
            dateDay={dateDay}
            dateWeekday={dateWeekday}
            itemTagsProps={itemTagsProps}
            showArrowButton={showArrowButton}
            title={title}
          />
        </Box>
        <Box
          h="70px"
          w="100%"
          borderRadiusBottomLeft={borderRadius.card}
          borderRadiusBottomRight={borderRadius.card}
          px={2}
          backgroundColor={bottomBarBackgroundColor}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            h="100%"
            w="80px"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mt={1}
            mb={1}
            pl={8}
            pr={8}
          >
            <Typography
              display="block"
              color={colors.primary.white.lightest}
              fontSize="fontSize75"
              fontWeight="bold"
              lineHeight="100%"
              textTransform="uppercase"
            >
              {bottomBarLabel}:
            </Typography>
          </Box>
          <Box
            h="100%"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mt={1}
            mb={1}
          >
            <Typography
              display="block"
              color={colors.primary.white.lightest}
              fontSize="fontSize75"
              fontWeight="regular"
              lineHeight="100%"
              textTransform="uppercase"
            >
              {bottomBarText}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      h={cardHeight}
      w="100%"
      minWidth="295px"
      borderRadius={borderRadius.card}
      backgroundColor={cardBackgroundColor}
      hoverBackgroundColor={cardHoverBackgroundColor}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      data-qaid="compact-item-card"
    >
      <CardContents
        bodyText={bodyText}
        itemTagsProps={itemTagsProps}
        title={title}
        dateMonth={dateMonth}
        dateDay={dateDay}
        dateWeekday={dateWeekday}
        showArrowButton={showArrowButton}
      />
    </Box>
  );
};
