import React from 'react';
import { useIntl } from 'react-intl';

import { SOFAR_PRIVACY_POLICY_URL } from 'app/shared/utils/siteUrls';
import { Link, Typography } from 'app/fan/components/atoms';

interface TermsAndConditionsProps {
  color?: string;
  fontSize?: string;
  linkVariant?: 'primary' | 'secondary' | 'tertiary';
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  color,
  fontSize = 'fontSize75',
  linkVariant = 'secondary',
}) => {
  const { formatMessage } = useIntl();

  return (
    <Typography fontSize={fontSize} color={color}>
      {formatMessage({ id: 'termsAndConditions.intro' })}
      <Link
        data-qaid="terms-and-conditions"
        href="/terms_and_conditions"
        fontWeight="regular"
        fontSize={fontSize}
        openInNewTab={true}
        textDecoration="underline !important"
        variant={linkVariant}
      >
        {formatMessage({
          id: 'shared.termsOfService',
        })}
      </Link>
      {formatMessage({ id: 'shared.termsAndConditions.midfix' })}
      <Link
        data-qaid="privacy-policy"
        href={SOFAR_PRIVACY_POLICY_URL}
        fontWeight="regular"
        fontSize={fontSize}
        openInNewTab={true}
        textDecoration="underline !important"
        variant={linkVariant}
      >
        {formatMessage({ id: 'shared.privacyPolicy' })}
      </Link>
      .
    </Typography>
  );
};
