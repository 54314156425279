import { useEffect, useMemo, useRef, useState } from 'react';

interface PortalProps {
  isOpen: boolean;
  close: () => void;
  transitionSpeed: number;
  portalRootId?: string;
}

// Sets a transition boolean to allow for animations to finish
const useMountTransition = (isOpen: boolean, transitionSpeed: number) => {
  // Represents the state in which the portal is open and done animating, or is in the process of closing
  const [isOpenOrClosing, setIsOpenOrClosing] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timer | undefined;

    if (isOpen && !isOpenOrClosing) {
      // Set a zero-second timeout so React knows not to skip the animation
      timeoutId = setTimeout(() => setIsOpenOrClosing(true), 0);
    }
    if (!isOpen && isOpenOrClosing) {
      timeoutId = setTimeout(() => setIsOpenOrClosing(false), transitionSpeed);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isOpen, isOpenOrClosing]);

  return isOpenOrClosing;
};

function createPortalRoot(id: string) {
  const portalRoot = document.createElement('div');
  portalRoot.setAttribute('id', id);

  return portalRoot;
}

export const usePortal = ({
  isOpen,
  close,
  transitionSpeed,
  portalRootId = 'sofar-portal-root',
}: PortalProps) => {
  const { current: bodyElement } = useRef(document.querySelector('body'));
  const portalRootRef = useRef(
    document.getElementById(portalRootId) || createPortalRoot(portalRootId)
  );

  // Append portal root on mount
  useEffect(() => {
    if (bodyElement) {
      bodyElement.appendChild(portalRootRef.current);
      const portalElement = portalRootRef.current;

      return () => {
        // Clean up the portal when drawer component unmounts
        portalElement.remove();
        // Ensure scroll overflow is removed
        bodyElement.style.overflow = '';
      };
    }
    return;
  }, []);

  // Prevent page scrolling when the drawer is open
  useEffect(() => {
    function updatePageScroll(isOpen: boolean) {
      if (bodyElement) {
        if (isOpen) {
          bodyElement.style.overflow = 'hidden';
        } else {
          bodyElement.style.overflow = '';
        }
      }
    }

    updatePageScroll(isOpen);
  }, [isOpen]);

  // Allow Escape key to dismiss the drawer
  useEffect(() => {
    function closeDrawerOnEscKey(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        close();
      }
    }
    if (isOpen) {
      window.addEventListener('keyup', closeDrawerOnEscKey);
    }
    return () => {
      window.removeEventListener('keyup', closeDrawerOnEscKey);
    };
  }, [isOpen, close]);

  const isOpenOrClosing = useMountTransition(isOpen, transitionSpeed);
  const isShowing = isOpen && isOpenOrClosing;

  // Components should return null if isUnmounted is true
  const isUnmounted = !isOpenOrClosing && !isOpen;

  return useMemo(() => {
    return { portalRootRef, isUnmounted, isShowing };
  }, [portalRootRef, isUnmounted, isShowing]);
};
