import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils';
import { BasePageBox } from 'app/shared/components/molecules';
import { NavigationButton } from 'app/shared/components/molecules';
import {
  Box,
  Grid,
  GridItem,
  Image,
  Typography,
} from 'app/fan/components/atoms';

// We need to use perentages for the height of the image to keep the image ratio
// on the image regardlesss of the screen size on mobile and tablet
const ImageBox = styled(Box)`
  ${({ theme }) => css`
  ${theme.media.mobile`
    padding-top: 65%;
  `}

  ${theme.media.tablet`
    padding-top: 55%;
  `}

  ${theme.media.smallDesktop`
    padding-top: 0;
  `}
`}
`;

export const VisaSection: React.FC = () => {
  const { colors, fonts } = useCurrentTheme();
  const { isMobile } = useIsBreakpointSize();
  const { formatMessage } = useIntl();

  return (
    <BasePageBox
      backgroundColor={colors.primary.black.base}
      py={[10, 10, 20, 20]}
    >
      <>
        <Grid smallDesktopGap={8}>
          <GridItem mobile={12} smallDesktop={5}>
            <ImageBox
              h="100%"
              backgroundImage="https://sofar-sounds-live.imgix.net/homepage-visa-banner.png?ixlib=rails-4.2.0&auto=format&fit=crop&crop=faces&w=800&h=500"
              backgroundPosition="center"
            ></ImageBox>
          </GridItem>
          <GridItem mobile={12} smallDesktop={5}>
            <Image
              maxHeight="50px"
              maxWidth="230px"
              src="/visa-sofar-logo-white.svg"
              mb={8}
              mt={[10, 10, 0, 0]}
            />
            <Typography
              fontSize="fontSize600"
              fontFamily={fonts.title}
              color={colors.primary.white.base}
              mb={8}
            >
              {formatMessage({ id: 'home.visaBanner.title' })}
            </Typography>
            <Typography
              color={colors.primary.white.base}
              fontSize="fontSize300"
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'home.visaBanner.preview',
                }),
              }}
            />
            <NavigationButton mt={8} fullWidth={isMobile} href="/visaoffer">
              {formatMessage({ id: 'home.visaBanner.cta' })}
            </NavigationButton>
          </GridItem>
        </Grid>
      </>
    </BasePageBox>
  );
};

export default VisaSection;
