import gql from 'graphql-tag';

export const CREATE_ORDER = gql`
  mutation createOrder(
    $stripePaymentId: String!
    $eventId: Int!
    $numTickets: Int!
    $promoCodeCode: String
    $isVisaFreeTicketRedeemed: Boolean
    $checkoutVersion: Int!
    $isExpressCheckout: Boolean
    $guestUserId: Int
  ) {
    createOrder(
      stripePaymentId: $stripePaymentId
      eventId: $eventId
      numTickets: $numTickets
      promoCodeCode: $promoCodeCode
      isVisaFreeTicketRedeemed: $isVisaFreeTicketRedeemed
      checkoutVersion: $checkoutVersion
      isExpressCheckout: $isExpressCheckout
      guestUserId: $guestUserId
    ) {
      id
      externalConfirmationId
      eventAttendee {
        id
        ticketsCount
        user {
          id
          email
          firstName
          lastName
          isGuest
          lastAuthMethod
          resetPasswordToken
          mobile {
            countryCodeIso3166
            numberBody
          }
          optInFanMarketingSmses
          homeCity {
            id
            country {
              id
              countryCode
            }
          }
        }
      }
    }
  }
`;
