import { ObjectImageMap } from 'app/typings/images';
import { VenueForFanFacingEvent } from 'app/typings/venues';

import { Agency } from './agencies';
import { Artist } from './artists';
import { BusinessOwner } from './businessOwners';
import { Campaign } from './campaigns';
import { Cancellation } from './cancellation';
import { City } from './cities';
import { Comment } from './comments';
import { Competition, EventCompetitionForAdmin } from './competitions';
import { CuratorGroup } from './curatorGroups';
import {
  EventAttendee,
  EventAttendeeForGetEventsQuery,
} from './eventAttendees';
import { EventCollection } from './eventCollections';
import { EventFormatExperiment } from './eventFormatExperiments';
import { Neighborhood } from './neighborhoods';
import { PerformanceOffer, VenueOffer } from './offers';
import { Performance } from './performances';
import { Property, PropertyMap } from './properties';
import { Role } from './roles';
import { Tag } from './tags';
import { Theme } from './themes';
import { User } from './users';
import { Seating, Venue } from './venues';

export enum EventOrganizedAs {
  O_AND_O = 'o_and_o',
  CURATOR_OWNER_COMMERCIAL = 'curator_owner_commercial_event',
  CURATOR_OWNER_NONCOMMERCIAL = 'curator_owner_noncommercial_event',
}

export interface Event {
  artists?: Artist[];
  id: string;
  isPublished?: boolean;
  isGloballyPromoted?: boolean;
  cancelled?: boolean;
  cancellations?: any;
  city: City;
  isSpecialEvent?: boolean;
  specialEventUrl?: string;
  eventFormatExperiment?: EventFormatExperiment;
  crewLoadInAt?: string;
  artistLoadInAt?: string;
  onSaleAt?: string;
  guestsArriveAt: string;
  localStartsAt: string;
  startsAt: string;
  endsAt: string;
  price: number;
  currencySymbol?: string;
  remainingSpaces: number;
  maxTicketsPerAttendee: number;
  maximumPromoCodeUses?: number;
  maximumPercentagePromoCodeUses?: number;
  maximumGuaranteedPromoCodeUses?: number;
  maximumCustomPromoCodeUses?: number;
  landscapeImageUrl?: string;
  landscapeNarrowImageUrl?: string;
  landscapeVeryNarrowImageUrl?: string;
  billingRegion: string;
  billingPublishableKey: string;
  isPurchasable?: boolean;
  isAvailable?: boolean;
  isAppliable?: boolean;
  isAutoInviteEnabled?: boolean;
  isSoldOut?: boolean;
  neighborhood?: Neighborhood;
  curatorGroup?: CuratorGroup;
  campaign?: Campaign;
  venue?: Venue;
  performanceOffers?: PerformanceOffer[];
  performances?: Performance[];
  staff?: EventStaffMember[];
  theme?: Theme;
  byob?: boolean;
  alcoholForSale?: boolean;
  alcoholNotPermittedOrSold?: boolean;
  attendeeFlow: 'buy' | 'apply';
  isOutdoor?: boolean;
  showPet?: boolean;
  accessible?: boolean;
  foodAvailableForSale?: boolean;
  noOutsideFood?: boolean;
  isOver21?: boolean;
  isOver18?: boolean;
  faceMaskRequired?: boolean;
  faceMaskNotRequired?: boolean;
  attendee?: EventAttendee;
  artistCompensation?: number;
  eventArtistCompensation?: number;
  showExternalEventNotes: boolean;
  externalEventNotes?: string;
  internalVenueNotes?: string;
  externalVenueNotes?: string;
  headline?: string;
  useFromEmailForAllEmails?: boolean;
  fromEmail?: string;
  mainArtistOps?: User;
  mainCrewOps?: User;
  mainVenueOps?: User;
  imageUrl: string;
  images: ObjectImageMap;
  eventOrganizedAs: string;
  numTicketsAvailableForSale: number;
  cachedSlug?: string;
  isVenueConfirmed: boolean;
  maxPaInputs: MaxPaInputs;
  isFilmed: boolean;
  eventAttendeeGuestsConfirmedCount?: number;
  staffDayOfShowCount?: number;
  totalHeadcount?: number;
  vipAttendeeGuestsCount?: number;
  type?: string;
  serviceFee?: number;
  bookingFee: number | null;
  eventBookingFee?: number;
  onSaleEnded?: boolean;
  inOnSaleWindow?: boolean;
  cityTicketPrice?: number;
  competition?: Competition;
  onPresale?: boolean;
  properties?: Property[];
  merchandisingProperties?: Property[];
  propertyMap?: PropertyMap;
  sponsorNotes?: string;
  sponsorPostShowSummary?: string;
  venueBudget?: number;
  venueBudgetDescription?: string;
  agency?: Agency;
  sofarOperatedMarketType?: string;
  formatType?: string;
  businessOwner?: BusinessOwner;
  showInNearbyPrimaryCities?: boolean;
  isArtistSelfBookingEnabled?: boolean;
  haveAnyArtistsIndicatedSpecificAvailabilityOnEventDate?: boolean;
  seating?: Seating;
  timingNotes?: string;
  audioNotes?: string;
  isAudioStaffMemberActivated?: boolean;
  isGearRunnerStaffMemberActivated?: boolean;
  mcNotes?: string;
  seatingNotes?: string;
  suppliesNeededNotes?: string;
  eventCollections?: EventCollection[];
  eventType?: string;
  status?: string;
  position?: number;
  genres?: string;
  partnerPromotionTitle?: string;
  partnerPromotionDescription?: string;
  partnerPromotionButtonText?: string;
  partnerPromotionButtonUrl?: string;
  partnerPromotionSectionEnabled?: boolean;
  __typename?: string;
}

export interface EventPreview {
  id: string;
  localStartsAt: string;
  price: number;
  eventPrice?: number;
  theme: Theme;
  byob: boolean;
  alcoholForSale?: boolean;
  alcoholNotPermittedOrSold?: boolean;
  foodAvailableForSale?: boolean;
  noOutsideFood?: boolean;
  isOver21?: boolean;
  isOver18?: boolean;
  isOutdoor: boolean;
  isPublished: boolean;
  isGloballyPromoted?: boolean;
  accessible: boolean;
  showPet: boolean;
  imageUrl: string;
  venue: Venue;
  city: City;
  neighborhood?: Neighborhood;
  curatorGroup?: CuratorGroup;
  startsAt: string;
  crewLoadInAt?: string;
  artistLoadInAt?: string;
  guestsArriveAt: string;
  arrivalTime?: string;
  onSaleAt?: string;
  endsAt?: string;
  isPurchasable: boolean;
  isAvailable: boolean;
  isAppliable: boolean;
  isAutoInviteEnabled?: boolean;
  isSoldOut: boolean;
  cancelled: boolean;
  attendee?: EventAttendee;
  artistCompensation?: number;
  eventArtistCompensation?: number;
  internalVenueNotes?: string;
  externalVenueNotes?: string;
  inviteEmailMessage?: string;
  revealAddressEmailMessage?: string;
  seating?: string;
  useFromEmailForAllEmails?: boolean;
  fromEmail?: string;
  mainArtistOps?: User;
  mainCrewOps?: User;
  mainVenueOps?: User;
  type: string;
  performances: Performance[];
  performanceOffers: PerformanceOffer[];
  venueOffers?: VenueOffer[];
  staff: EventStaffMember[];
  numTicketsAvailableForSale: number;
  remainingSpaces: number;
  totalBookingFee: number;
  revenue: number;
  ticketRevenue: number;
  currencySymbol?: string;
  cachedSlug?: string;
  eventOrganizedAs: string;
  isVenueConfirmed: boolean;
  maxPaInputs: MaxPaInputs;
  isFilmed: boolean;
  artists?: Artist[];
  attendeeFlow?: 'buy' | 'apply';
  competition?: EventCompetitionForAdmin;
  status?: string;
  comments?: Comment[];
  commentsCount?: number;
  totalGuestsConfirmedCount: number;
  totalAttendeesCount?: number;
  totalAttendeesConfirmedCount?: number;
  totalAttendeesUnconfirmedCount?: number;
  eventAttendeesAppliedCount?: number;
  eventAttendeesGuestlistedCount?: number;
  eventAttendeesCantGoCount?: number;
  eventAttendeesConfirmedCount?: number;
  eventAttendeeGuestsAppliedCount?: number;
  eventAttendeeGuestsGuestlistedCount?: number;
  eventAttendeeGuestsCantGoCount?: number;
  eventAttendeeGuestsConfirmedCount?: number;
  vipAttendeeGuestsCount?: number;
  staffDayOfShowCount?: number;
  totalHeadcount?: number;
  maxTicketsPerAttendee: number;
  maximumPromoCodeUses?: number;
  maximumPercentagePromoCodeUses?: number;
  maximumGuaranteedPromoCodeUses?: number;
  maximumCustomPromoCodeUses?: number;
  dealAmount?: number;
  properties?: Property[];
  merchandisingProperties?: Property[];
  propertyMap?: PropertyMap;
  cityTicketPrice?: number;
  isSpecialEvent?: boolean;
  specialEventUrl?: string;
  eventFormatExperiment?: EventFormatExperiment;
  showExternalEventNotes?: boolean;
  externalEventNotes?: string;
  headline?: string;
  bookingFee: number | null;
  eventBookingFee?: number;
  campaign?: Campaign;
  onPresale?: boolean;
  sponsorNotes?: string;
  sponsorPostShowSummary?: string;
  venueBudget?: number;
  venueBudgetDescription?: string;
  agency?: Agency;
  sofarOperatedMarketType?: string;
  formatType?: string;
  businessOwner?: BusinessOwner;
  showInNearbyPrimaryCities?: boolean;
  isArtistSelfBookingEnabled?: boolean;
  haveAnyArtistsIndicatedSpecificAvailabilityOnEventDate?: boolean;
  audioNotes?: string;
  mcNotes?: string;
  seatingNotes?: Seating;
  suppliesNeededNotes?: string;
  timingNotes?: string;
  images: ObjectImageMap;
  eventCollections?: EventCollection[];
  cancellations?: Cancellation[];
  partnerPromotionTitle?: string;
  partnerPromotionDescription?: string;
  partnerPromotionButtonText?: string;
  partnerPromotionButtonUrl?: string;
  partnerPromotionSectionEnabled?: boolean;
  __typename?: string;
}

export interface EventForEventPage {
  id: string;
  localStartsAt: string;
  startsAt: string;
  price: number;
  theme?: Theme;
  byob: boolean;
  isOutdoor: boolean;
  accessible: boolean;
  neighborhood?: Neighborhood;
  venue: {
    id: number;
    venueType?: string;
    venueCategories: Tag[];
    neighborhood?: Neighborhood;
  };
  city: {
    id: number;
    title: string;
    timezone?: string;
    cachedSlug: string;
    country: {
      id: number;
      currencySymbol: string;
      countryCode: string;
    };
  };
  guestsArriveAt: string;
  isGloballyPromoted: boolean;
  isPublished: boolean;
  isPurchasable: boolean;
  isAppliable: boolean;
  attendeeFlow: 'buy' | 'apply';
  isSoldOut: boolean;
  cancelled: boolean;
  attendee?: EventAttendeeForGetEventsQuery;
  isVenueConfirmed: boolean;
  isSpecialEvent?: boolean;
  specialEventUrl?: string;
  onPresale: boolean;
  images: ObjectImageMap;
  // the following is needed only as a fallback
  imageUrl: string;
  genres?: string;
}

export interface EventForEventGrid {
  id: string;
  localStartsAt: string;
  price: number;
  theme: Theme;
  genres?: string;
  byob: boolean;
  isOutdoor: boolean;
  accessible: boolean;
  showPet: boolean;
  imageUrl: string;
  venue: VenueForFanFacingEvent;
  city: {
    id: number;
    title: string;
    timezone?: string;
    country: {
      id: number;
      countryCode: string;
      currency: string;
      currencySymbol: string;
    };
  };
  startsAt: string;
  guestsArriveAt: string;
  isPurchasable: boolean;
  isAvailable: boolean;
  isSoldOut: boolean;
  cancelled: boolean;
  attendee?: EventAttendeeForGetEventsQuery;
  isVenueConfirmed: boolean;
  attendeeFlow?: 'buy' | 'apply';
  isSpecialEvent?: boolean;
  specialEventUrl?: string;
  onPresale?: boolean;
}

export interface EventForFilmstripSection {
  id: string;
  guestsArriveAt: string;
  imageUrl: string;
  isVenueConfirmed: boolean;
  isPurchasable: boolean;
  cancelled: boolean;
  onPresale: boolean;
  venue: VenueForFanFacingEvent;
  genres?: string;
  city: {
    id: number;
    timezone?: string;
    title: string;
    cachedSlug: string;
  };
}

export interface EventForEventCollection {
  id: string;
  localStartsAt: string;
  guestsArriveAt: string;
  startsAt: string;
  isSoldOut: boolean;
  isVenueConfirmed: boolean;
  theme?: Theme;
  genres?: string;
  isGloballyPromoted: boolean;
  isPublished: boolean;
  isPurchasable: boolean;
  isAppliable: boolean;
  attendeeFlow: 'buy' | 'apply';
  attendee?: EventAttendeeForGetEventsQuery;
  cancelled: boolean;
  onPresale: boolean;
  venue: VenueForFanFacingEvent;
  imageUrl: string;
  byob?: boolean;
  city: {
    id: number;
    title: string;
    cachedSlug: string;
    timezone?: string;
    country: {
      countryCode: string;
      currencySymbol: string;
    };
  };
}

export interface EventForCityPage {
  id: string;
  guestsArriveAt: string;
  localStartsAt: string;
  startsAt: string;
  isSoldOut: boolean;
  isVenueConfirmed: boolean;
  genres?: string;
  theme: Theme;
  attendee?: EventAttendeeForGetEventsQuery;
  isGloballyPromoted: boolean;
  isPublished: boolean;
  isPurchasable: boolean;
  isAppliable: boolean;
  attendeeFlow: 'buy' | 'apply';
  cancelled: boolean;
  onPresale: boolean;
  neighborhood?: Neighborhood;
  venue: VenueForFanFacingEvent;
  city: {
    id: number;
    title: string;
    timezone?: string;
    cachedSlug: string;
    country: {
      id: number;
      countryCode: string;
      currencySymbol: string;
    };
  };
}

interface EventsQueryCompletedProps {
  events:
    | EventForEventGrid[]
    | EventForFilmstripSection[]
    | EventForEventCollection[]
    | EventForCityPage[]
    | Event[];
  metadata: { totalRecords: number; currentPage?: number };
  perPage: number;
  appliedFilterKeys: string[];
  user: User | null;
  productListType: string;
  skipProductListFiltered?: boolean;
  trackEventGenres?: boolean;
  trackEventGenresByEventId?: {};
}

export type OnGetEventsQueryCompletedType = ({
  events,
  metadata,
  perPage,
  appliedFilterKeys,
  user,
  productListType,
  skipProductListFiltered,
  trackEventGenres,
  trackEventGenresByEventId,
}: EventsQueryCompletedProps) => void;

export interface EventPlannerEvent {
  accessible: boolean;
  attendeeFlow: 'buy' | 'apply';
  totalBookingFee: number;
  byob: boolean;
  city: City;
  crewLoadInAt: string;
  eventAttendeeGuestsConfirmedCount: number;
  eventOrganizedAs: string;
  guestsArriveAt: string;
  haveAnyArtistsIndicatedSpecificAvailabilityOnEventDate: boolean;
  localStartsAt: string;
  id: number;
  imageUrl: string;
  isAppliable: boolean;
  isAvailable: boolean;
  isFilmed: boolean;
  isGloballyPromoted: boolean;
  isOutdoor: boolean;
  isPublished: boolean;
  isPurchasable: boolean;
  isSoldOut: boolean;
  isSpecialEvent: boolean;
  isVenueConfirmed: boolean;
  maxPaInputs: MaxPaInputs;
  neighborhood: Neighborhood;
  numTicketsAvailableForSale: number;
  performances: Performance[];
  performanceOffers: PerformanceOffer[];
  price: number;
  remainingSpaces: number;
  revenue: number;
  staff: EventStaffMember[];
  startsAt: string;
  status: string;
  showPet: boolean;
  ticketRevenue: number;
  totalGuestsConfirmedCount: number;
  theme: Theme;
  type: string;
  venue: Venue;
  venueOffers: VenueOffer[];
  vipAttendeeGuestsCount: number;
  cachedSlug?: string;
  campaign?: Campaign;
  comments?: Comment[];
  commentsCount?: number;
  curatorGroup?: CuratorGroup;
  currencySymbol?: string;
  endsAt?: string;
  onPresale?: boolean;
  staffDayOfShowCount?: number;
  specialEventUrl?: string;
  eventFormatExperiment?: EventFormatExperiment;
  eventCollections?: EventCollection[];
}

export interface EventStaffMember {
  id?: number;
  user?: User;
  role: Role;
  userAddedAt?: string;
}

export interface UserStaffedEvent {
  id: number;
  cancelled: boolean;
  city: City;
  localStartsAt: string;
  providedFeedback?: boolean;
  staff: EventStaffMember[];
  theme: Theme;
  type: string;
}

export interface MaxPaInputs {
  key: string;
  name: string;
}

export interface GetEventForFanData {
  event?: Event;
}

export interface GetEventForFanVariables {
  id?: number;
  cachedSlug?: string;
  eventTypes?: string[];
  includeTodaysArtists?: boolean;
}

export interface Charge {
  id?: string;
  amount: number;
  uid?: string;
}

// NOTE: GeneralEvent interface combines the needed fields of Events, FeaturedSets
// and Booking Requests
export interface GeneralEvent {
  id: number | string;
  localStartsAt?: string;
  eventType?: string;
  cancelled?: boolean;
  cancellations?: any;
  city: City;
  availabilityStart?: string;
  availabilityEnd?: string;
  status?: string;
  position?: number;
  venue?: Venue;
  artists?: Artist[];
  performances?: Performance[];
}
