import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { EventForEventCollection } from 'app/typings';
import { EventForCityPage } from 'app/typings';
import { neighborhoodOrSecretLocation } from 'app/fan/utils';
import { formattedEventDateAndTime } from 'app/fan/utils/eventDateTime';
import { useDisplayEventGenres } from 'app/fan/utils/useEventGenresExperiment';
import {
  EventForEventTags,
  useGetEventTagsProps,
} from 'app/fan/utils/useGetEventTagsProps';
import { Link } from 'app/fan/components/atoms';
import { CompactItemCard } from 'app/fan/components/molecules';

interface CityForCompactEventCard {
  id: number;
  title: string;
  timezone?: string;
  cachedSlug: string;
  country: {
    countryCode?: string;
  };
}

interface CompactEventCardProps {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  event: EventForEventCollection | EventForCityPage;
  city?: CityForCompactEventCard;
  userAttendeeState?: string;
  featureFlagsEnabled?: { [key: string]: boolean };
  onClick?: () => void;
  w?: string;
  'data-qaid': string;
}

const getTitle = (
  event: EventForEventCollection | EventForCityPage,
  formatMessage: IntlShape['formatMessage'],
  city?: CityForCompactEventCard
) => {
  if (city) {
    return neighborhoodOrSecretLocation(event, formatMessage);
  } else {
    return event.city.title;
  }
};

export const CompactEventCard: React.FC<CompactEventCardProps> = ({
  backgroundColor,
  hoverBackgroundColor,
  event,
  city,
  userAttendeeState,
  featureFlagsEnabled,
  onClick,
  w = '100%',
  'data-qaid': qaId,
}) => {
  const { formatMessage } = useIntl();
  const displayEventGenres = useDisplayEventGenres({
    eventGenresFeatureFlagEnabled: featureFlagsEnabled?.eventGenres,
    citySlug: city?.cachedSlug || event.city.cachedSlug,
  });
  const getEventTagsProps = useGetEventTagsProps();

  const bottomBarLabel =
    displayEventGenres && event.hasOwnProperty('genres')
      ? formatMessage({ id: 'shared.genres' })
      : undefined;

  const bottomBarText =
    displayEventGenres && event.hasOwnProperty('genres')
      ? event.genres || formatMessage({ id: 'shared.secret' })
      : undefined;

  const {
    eventShortMonth,
    eventDay,
    eventShortWeekday,
  } = formattedEventDateAndTime(
    event.guestsArriveAt,
    event.city.timezone,
    event.city.country.countryCode
  );

  return (
    <Link
      textDecoration="none"
      href={`/events/${event.id}`}
      onClick={onClick}
      controlChildSVGStyle={false}
      w={w}
      h="100%"
      data-qaid={qaId}
    >
      <CompactItemCard
        backgroundColor={backgroundColor}
        hoverBackgroundColor={hoverBackgroundColor}
        title={getTitle(event, formatMessage, city)}
        dateMonth={eventShortMonth}
        dateDay={eventDay}
        dateWeekday={eventShortWeekday}
        itemTagsProps={getEventTagsProps(
          event as EventForEventTags,
          userAttendeeState
        )}
        bottomBarLabel={bottomBarLabel}
        bottomBarText={bottomBarText}
      />
    </Link>
  );
};
