import gql from 'graphql-tag';

export const GET_ARTIST_FOLLOWING = gql`
  query GetArtistFollowing($artistId: Int!, $userId: Int!) {
    artistFollowing(artistId: $artistId, userId: $userId) {
      id
      following
    }
  }
`;
