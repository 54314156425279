import { Event, EventOrganizedAs } from 'app/typings';
import { dateFormatter } from 'app/shared/utils/datetime';
import { EventStatus } from 'app/shared/utils/events';
import { includeBookingFeeInDisplayPrice } from 'app/fan/utils/bookingFee';

interface AddEventStructuredDataParams {
  event: Event;
  venueLocation: string;
  force?: boolean;
}

interface EventForGetEventStatus {
  isPurchasable?: boolean;
  isSoldOut?: boolean;
  isPublished?: boolean;
  cancelled?: boolean;
  attendeeFlow?: string;
  isAppliable?: boolean;
}

export function getEventStatus(event: EventForGetEventStatus) {
  if (!event.isPurchasable && !event.isSoldOut && event.isPublished) {
    return EventStatus.PAUSED;
  } else if (event.cancelled) {
    return EventStatus.CANCELLED;
  } else if (event.isSoldOut && !event.cancelled) {
    return EventStatus.SOLDOUT;
  } else if (
    event.attendeeFlow == 'apply' &&
    !event.isAppliable &&
    !event.cancelled
  ) {
    return EventStatus.SOLDOUT;
  }
  return EventStatus.NONE;
}

export function isUpcomingEvent(event?: { startsAt: string }) {
  if (!event) {
    return false;
  }

  const startDate = new Date(event.startsAt).getTime();
  const currentDate = new Date().getTime();

  return startDate - currentDate > 0;
}

export function isEventIndexable(event?: Event) {
  if (!event) {
    return false;
  }
  const { onPresale, isAvailable, attendeeFlow } = event;

  return (
    isUpcomingEvent(event) &&
    !onPresale &&
    isAvailable &&
    attendeeFlow === 'buy'
  );
}

export function addEventStructuredData({
  event,
  venueLocation,
  force = false,
}: AddEventStructuredDataParams) {
  const scriptId = `structured-data-event-${event.id}`;

  if (document.getElementById(scriptId) && !force) {
    return;
  }

  const {
    landscapeImageUrl,
    landscapeNarrowImageUrl,
    landscapeVeryNarrowImageUrl,
    localStartsAt,
    guestsArriveAt,
    onSaleAt,
    theme,
    city: {
      title: cityTitle,
      country: { currency },
    },
    price,
  } = event;

  const structuredDataScriptTag = document.createElement('script');
  structuredDataScriptTag.setAttribute('type', 'application/ld+json');

  const eventDayOfWeek = dateFormatter(localStartsAt, 'longWeekday');

  const eventName = theme
    ? `Sofar Sounds ${cityTitle} Concert - ${theme.title}`
    : `Sofar Sounds ${cityTitle} Concert`;

  const eventDescription = `Through live concerts, Sofar creates intimate music experiences in unique spaces. Come out on ${eventDayOfWeek} and discover 3 artists in ${venueLocation}`;

  const eventImageList = [
    landscapeImageUrl,
    landscapeNarrowImageUrl,
    landscapeVeryNarrowImageUrl,
  ].filter((url?: string) => !!url);

  const data = {
    '@context': 'https://schema.org',
    '@type': 'MusicEvent',
    name: eventName,
    startDate: guestsArriveAt,
    doorTime: guestsArriveAt,
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'Place',
      name: venueLocation,
      address: cityTitle,
    },
    image: eventImageList,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    description: eventDescription,
    offers: {
      '@type': 'Offer',
      url: `https://www.sofarsounds.com/events/${event.id}`,
      price,
      priceCurrency: currency,
      availability: 'InStock',
      availabilityStarts: onSaleAt,
      validFrom: onSaleAt,
    },
    organizer: {
      '@type': 'Organization',
      name: 'Sofar Sounds',
      url: 'https://sofarsounds.com',
    },
    url: `https://www.sofarsounds.com/events/${event.id}`,
    type: 'MusicEvent',
  };

  structuredDataScriptTag.textContent = JSON.stringify(data);
  structuredDataScriptTag.id = scriptId;
  document.head.appendChild(structuredDataScriptTag);
}

interface EventForDisplayPrice {
  id: string;
  price: number;
  eventOrganizedAs: string;
  type?: string;
  bookingFee: number | null;
  city: {
    id: number;
    displayBookingFee?: boolean;
    cachedSlug: string;
    country: {
      id: number;
      displayBookingFee?: boolean;
    };
  };
}

export function calculateDisplayPrice(event: EventForDisplayPrice) {
  if (event.eventOrganizedAs === EventOrganizedAs.CURATOR_OWNER_NONCOMMERCIAL) {
    return 0;
  }

  if (includeBookingFeeInDisplayPrice(event)) {
    const bookingFee = (event.bookingFee || 0) / 100;
    return event.price + bookingFee;
  }

  return event.price;
}
