import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { EventAttendee, PaymentMethod, User } from 'app/typings';
import { DISCOVERY_EVENT } from 'app/shared/utils/events';
import { useVisaAuth } from 'app/shared/utils/visa';
import { CheckoutEvent, CheckoutEventAttendee } from 'app/fan/utils/checkout';

import { useSavedCards } from './useSavedCards';

export interface VisaPartnershipCheckoutLogic {
  visaPreSaleEligible?: boolean;
  visaFreeTicketsRemaining: number;
  useVisaPartnershipPaymentForm: boolean;
  cardLast4?: string;
  canBypassVisaValidation?: boolean;
  canRedeemVisaFreeTicket: boolean;
  isRedeemingVisaFreeTicket: boolean;
  setIsRedeemingVisaFreeTicket: (value: boolean) => void;
  canRedeemAndIsRedeemingVisaFreeTicket: boolean;
  numberOfVisaFreeTickets: number;
  validateVisaLast4: (paymentMethod: string) => void;
  cardRedemptionsStatusLoading: boolean;
  activeCardError?: string;
}

interface Props {
  event: CheckoutEvent | null;
  eventId?: string | number;
  eventAttendee: EventAttendee | CheckoutEventAttendee | null;
  activeCardError?: string;
  isExpressCheckout: boolean;
  numberOfTickets: number;
  user?: User;
}

export const useVisaPartnershipCheckoutLogic = ({
  event,
  eventId,
  eventAttendee,
  isExpressCheckout,
  numberOfTickets,
  user,
}: Props) => {
  const { formatMessage } = useIntl();

  const [activeCardError, setActiveCardError] = useState<string>();
  const { savedCards } = useSavedCards(user || null);

  const {
    visaPreSaleEligible,
    visaFreeTicketsRemaining,
    visaFreeTicketRedeemedForEvent,
    cardLast4,
    canBypassVisaValidation,
    cardRedemptionsStatusLoading,
  } = useVisaAuth({
    eventId: Number(eventId),
    skipLoadCardRedemptionsStatus:
      !eventId || event?.type !== DISCOVERY_EVENT || isExpressCheckout,
  });

  const [searchParams] = useSearchParams();
  const forceVisaPromoVisible = searchParams.get('force_visa') === 'true';

  const [isRedeemingVisaFreeTicket, setIsRedeemingVisaFreeTicket] = useState(
    !isExpressCheckout && numberOfTickets > 1
  );

  const canRedeemVisaFreeTicket =
    !isExpressCheckout &&
    !!event &&
    event.type === DISCOVERY_EVENT &&
    event.eventOrganizedAs === 'o_and_o' &&
    ((visaPreSaleEligible && visaFreeTicketsRemaining > 0) ||
      (canBypassVisaValidation && forceVisaPromoVisible)) &&
    !visaFreeTicketRedeemedForEvent &&
    !event?.competition?.id &&
    (!eventAttendee || !eventAttendee.hasAppliedPromoCode);

  const canRedeemAndIsRedeemingVisaFreeTicket =
    canRedeemVisaFreeTicket && isRedeemingVisaFreeTicket;

  const numberOfVisaFreeTickets = canRedeemAndIsRedeemingVisaFreeTicket ? 1 : 0;

  const validateVisaLast4 = useCallback(
    (paymentMethod: string) => {
      const cardToValidate = savedCards.find(
        (card: PaymentMethod) => card.paymentMethodId === paymentMethod
      );

      if (
        cardLast4 &&
        cardToValidate &&
        cardToValidate.identifier != cardLast4 &&
        ((event && event.onPresale) || isRedeemingVisaFreeTicket)
      ) {
        const errorMessage = formatMessage({
          id: 'checkout.cardDetails.mustBeVisaVerifiedCard',
        });
        setActiveCardError(errorMessage);
      } else {
        setActiveCardError(undefined);
      }
    },
    [
      cardLast4,
      event,
      isRedeemingVisaFreeTicket,
      setActiveCardError,
      formatMessage,
    ]
  );

  // If user is eligible and is redeeming free ticket or
  // event is on presale (i.e. they used their 2 free tickets per year
  // but still can buy presale tickets) we want to display the visa
  // payment form
  const useVisaPartnershipPaymentForm =
    visaPreSaleEligible && (isRedeemingVisaFreeTicket || !!event?.onPresale);

  return useMemo<VisaPartnershipCheckoutLogic>(
    () => ({
      visaPreSaleEligible,
      visaFreeTicketsRemaining,
      useVisaPartnershipPaymentForm,
      cardLast4,
      canBypassVisaValidation,
      canRedeemVisaFreeTicket,
      isRedeemingVisaFreeTicket,
      setIsRedeemingVisaFreeTicket,
      canRedeemAndIsRedeemingVisaFreeTicket,
      numberOfVisaFreeTickets,
      validateVisaLast4,
      cardRedemptionsStatusLoading,
      activeCardError,
    }),
    [
      visaPreSaleEligible,
      visaFreeTicketsRemaining,
      useVisaPartnershipPaymentForm,
      cardLast4,
      canBypassVisaValidation,
      canRedeemVisaFreeTicket,
      isRedeemingVisaFreeTicket,
      setIsRedeemingVisaFreeTicket,
      canRedeemAndIsRedeemingVisaFreeTicket,
      numberOfVisaFreeTickets,
      validateVisaLast4,
      cardRedemptionsStatusLoading,
      activeCardError,
    ]
  );
};
