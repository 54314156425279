import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';

import { City, ModalStep } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { AuthContext } from 'app/shared/context/Auth';
import {
  AuthFormContextProvider,
  MultiStepSignUpContextProvider,
} from 'app/fan/context';
import { Box, Typography } from 'app/fan/components/atoms';
import { MultiStepSignUpWizard } from 'app/fan/components/organisms/MultiStepSignUpWizard';

const WizardSteps = {
  [ModalStep.COMPLETE_ACCOUNT]: 1,
  [ModalStep.SMS_OPT_IN]: 2,
};

export type OnNewsletterSuccess = ({
  isCompleteAccountModalOpen,
  modalStep,
  isLoading,
}: {
  isCompleteAccountModalOpen: boolean;
  modalStep: ModalStep;
  isLoading: boolean;
}) => void;

export const Success: React.FC<{
  userEmail: string;
  city?: City;
  formContext: string;
  onLoad?: OnNewsletterSuccess;
}> = ({ userEmail, city, formContext, onLoad }) => {
  const intl = useIntl();
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { loggedIn } = useContext(AuthContext);
  const [isCompleteAccountModalOpen, setIsCompleteAccountModalOpen] = useState(
    true
  );
  const initialStep = loggedIn
    ? ModalStep.SMS_OPT_IN
    : ModalStep.COMPLETE_ACCOUNT;
  const [modalStep, setModalStep] = useState<ModalStep>(initialStep);
  const [isLoading, setIsLoading] = useState(false);
  const { colors, fonts } = useCurrentTheme();

  useEffect(() => {
    const oneHourFromNow = new Date(Date.now() + 60 * 60 * 1000);
    Cookies.set('customerLeadCreated', 'true', {
      expires: oneHourFromNow,
    });
    trackAnalyticsEvent(
      'Newsletter Success',
      { email: userEmail, city: city?.cachedSlug, form_context: formContext },
      true
    );
  }, []);

  useEffect(() => {
    onLoad &&
      onLoad({
        isCompleteAccountModalOpen,
        modalStep,
        isLoading,
      });
  }, [onLoad]);

  return (
    <Box flexDirection="column" gap={4} data-qaid="newsletter-success">
      <Typography
        as="div"
        fontSize="fontSize400"
        fontWeight="medium"
        color={colors.primary.white.base}
        fontFamily={fonts.title}
      >
        {intl.formatMessage({ id: 'newsletter.welcomeToSofar' })}
      </Typography>
      <Typography color={colors.primary.white.base}>
        {intl.formatMessage({ id: 'newsletter.successBody' })}
      </Typography>
      <MultiStepSignUpContextProvider>
        <AuthFormContextProvider
          isCompleteSignUpFlow={true}
          isPlusOneFlow={false}
          isCheckoutAuth={false}
        >
          <MultiStepSignUpWizard
            smsMarketingSignUpTitle={intl.formatMessage({
              id: 'shared.smsSignUp.title',
            })}
            isOpen={isCompleteAccountModalOpen}
            setIsOpen={setIsCompleteAccountModalOpen}
            activeStep={WizardSteps[modalStep]}
            modalStep={modalStep}
            formContext={formContext}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            city={city}
            userEmail={userEmail}
            setModalStep={setModalStep}
          />
        </AuthFormContextProvider>
      </MultiStepSignUpContextProvider>
    </Box>
  );
};
