import { useLazyQuery } from '@apollo/react-hooks';
import { useQuery } from 'react-apollo';

import { AuthMethod } from 'app/typings';
import {
  EventAttendeeApiQueryParams,
  EventAttendeeApiResponse,
} from 'app/typings/eventAttendees';
import { queryParams } from 'app/shared/utils/queries';

import {
  GET_EVENT_ATTENDEE,
  GET_EVENT_ATTENDEE_FOR_EXPRESS_GUEST_CHECKOUT,
} from './queries';

export const GetEventAttendeeForFan = (
  params: EventAttendeeApiQueryParams = {}
) => {
  return useQuery<EventAttendeeApiResponse>(
    GET_EVENT_ATTENDEE,
    queryParams(params)
  );
};

export const GetEventAttendeeForFanLazy = (
  params: EventAttendeeApiQueryParams = {}
) => {
  return useLazyQuery<EventAttendeeApiResponse>(
    GET_EVENT_ATTENDEE,
    queryParams({ ...params, fetchPolicy: 'network-only' })
  );
};

export interface EventAttendeeForExpressGuestCheckout {
  id: string;
  user?: {
    id: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    isGuest?: boolean;
    lastAuthMethod?: AuthMethod;
    resetPasswordToken?: string;
  };
}

export interface EventAttendeeForExpressCheckoutData {
  eventAttendee: EventAttendeeForExpressGuestCheckout;
}

export interface EventAttendeeForExpressCheckoutVariables {
  id: number;
}

// Used on the checkout page's 'Order Complete' stage
export const GetEventAttendeeForExpressGuestCheckout = (
  params: EventAttendeeForExpressCheckoutVariables
) => {
  return useLazyQuery<EventAttendeeForExpressCheckoutData>(
    GET_EVENT_ATTENDEE_FOR_EXPRESS_GUEST_CHECKOUT,
    queryParams(params)
  );
};
