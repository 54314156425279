import { useLazyQuery } from 'react-apollo';

import {
  GetPromoCodeValidationData,
  GetPromoCodeValidationVariables,
} from 'app/typings/promoCodes';
import { queryParams } from 'app/shared/utils/queries';
import { GET_PROMO_CODE_VALIDATION } from 'app/fan/graphql/promoCodes/queries';

export const GetPromoCodeValidationLazy = (
  params: GetPromoCodeValidationVariables = {}
) => {
  return useLazyQuery<GetPromoCodeValidationData>(
    GET_PROMO_CODE_VALIDATION,
    queryParams(params)
  );
};
