import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { EventForEventCollection, EventForFilmstripSection } from 'app/typings';
import { CommonTheme, useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext, useIsBreakpointSize } from 'app/shared/utils';
import { Box } from 'app/fan/components/atoms';
import { CompactEventCard } from 'app/fan/components/organisms/CompactEventCard';

interface FilmstripProps {
  events: EventForFilmstripSection[] | EventForEventCollection[];
  qaId?: string;
  invertColors?: boolean;
}

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    padding: 0;

    ${theme.media.tablet`
      padding: 0 ${theme.ruler[theme.dimensions.horizontalPadding.page[1]]}px;
    `}

    ${theme.media.smallDesktop`
      padding: 0 calc(max(0px, ((100vw - ${
        theme.dimensions.maxPageWidth
      }px)/2)) + ${theme.ruler[theme.dimensions.horizontalPadding.page[2]]}px);
    `}

    ${theme.media.largeDesktop`
      padding: 0 calc(((100vw - ${theme.dimensions.maxPageWidth}px)/2) + ${
      theme.ruler[theme.dimensions.horizontalPadding.page[3]]
    }px);
    `}
  `}
`;

const ScrollContainer = styled(Box)<{ invertColors: boolean }>`
  ${({ theme, invertColors }) => css`
    overflow-x: scroll;
    scroll-behavior: smooth;

    ${invertColors &&
      `
      background-color: ${theme.colors.primary.black.base};
      color: ${theme.colors.primary.white.lightest};
    `}
  `}
`;

export const Filmstrip: React.FC<FilmstripProps> = ({
  events,
  qaId,
  invertColors = false,
}) => {
  const { dimensions }: CommonTheme = useCurrentTheme();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [trackedScroll, setTrackedScroll] = useState(false);
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { isMobile } = useIsBreakpointSize();

  const handleScroll = () => {
    if (!trackedScroll) {
      trackAnalyticsEvent('Filmstrip Scrolled', {
        dataQaid: `${qaId}-film-strip`,
      });
      setTrackedScroll(true);
    }
  };

  const triggerProductClickedEvent = (eventId: number) => {
    trackAnalyticsEvent('Filmstrip Product Clicked', {
      event_id: eventId,
    });
  };

  const eventsToDisplay =
    isMobile && events.length > 4 ? events.slice(0, 4) : events;

  const eventsContainerFlexDirection = () => {
    if (isMobile) {
      return 'column';
    }
    return 'row;';
  };

  const mainContainerJustifyContent = () => {
    if (isMobile) {
      return 'center';
    }
    return 'flex-start';
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={mainContainerJustifyContent()}
    >
      <ScrollContainer
        display="flex"
        alignItems="center"
        data-qaid={`${qaId}-film-strip`}
        ref={scrollContainerRef}
        onScroll={handleScroll}
        invertColors={invertColors}
        gap={3}
        w="100%"
        pt={8}
        pb={[8, 10, 10, 10]}
      >
        <StyledBox
          display="flex"
          flexDirection={eventsContainerFlexDirection()}
          columnGap={8}
          rowGap={4}
          w={
            eventsContainerFlexDirection() == 'column'
              ? '-webkit-fill-available'
              : 'auto'
          }
        >
          {eventsToDisplay.map((event: any) => (
            <Box key={event.id} px={dimensions.horizontalPadding.pageGridItem}>
              <CompactEventCard
                onClick={() => triggerProductClickedEvent(event.id)}
                data-qaid="filmstrip-event-card-link"
                event={event}
                city={event.city}
                featureFlagsEnabled={{ eventGenres: false }}
                w={isMobile ? '100%' : '395px'}
              />
            </Box>
          ))}
        </StyledBox>
      </ScrollContainer>
    </Box>
  );
};

export default Filmstrip;
